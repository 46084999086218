const enUS = {
  'cart.cart1': 'Shopping Cart',
  'cart.cart2': 'Shipping Information',
  'cart.cart3': 'Payment Method',
  'cart.cart4': 'Item',
  'cart.cart5': 'Qty',
  'cart.cart6': 'Price',
  'cart.cart7': 'Remove',
  'cart.cart8': 'Don’t want to build?',
  'cart.cart9': 'Let our master modellers do it for you!',
  'cart.cart10': 'Shipping',
  'cart.cart11': 'FREE',
  'cart.cart12': 'Order total',
  'cart.cart13': 'Expected delivery date',
  'cart.cart14': 'PROCEED TO CHECKOUT',
  'cart.cart15': 'Personal Information',
  'cart.cart16': 'Buyer First Name',
  'cart.cart17': 'Buyer Last Name',
  'cart.cart18': 'Your Email',
  'cart.cart19': 'Type Your Email Again',
  'cart.cart20': 'We’ll send your order confirmation and SF express tracking number to this email address',
  'cart.emaildisclaimer': "We'll send your order confirmation and tracking number to this email address.",
  'cart.mobiledisclaimer': 'So we can contact you in case of any problems when we deliver.',
  'cart.passworddisclaimer': 'Create an account to view your orders, receive free gifts and more. (Optional)',
  'cart.cart21': 'Shipping Address',
  'cart.cart22': 'Receiver Name',
  'cart.cart23': 'Please fill in full address',
  'cart.cart24': 'Address Line 2',
  'cart.cart25': 'District',
  'cart.cart26': 'Country',
  'cart.cart27': 'This purchase is a gift',
  'cart.pickupcheckbox': 'Pick up at STK Workshop',
  'cart.cart28': 'Your message to be included in the box',
  'cart.cart29': 'Continue',
  'cart.cart30': 'Order Summary',
  'cart.cart31': 'Pack One – The Complete Cockpit and Hold',
  'cart.cart32': 'Qty',
  'cart.cart33': 'Price',
  'cart.cart34': 'Thank you for your purchase!',
  'cart.cart35':
    'Your payment has been processed successfully. We have sent an order confirmation to your email address.',
  'cart.cart36': 'Back to homepage',
  'cart.cart37': 'Complete Create Account',
  'cart.cart38': 'Set up a password to create an account, so you can log in any time to see your order history.',
  'cart.cart39': 'Your email',
  'cart.cart40': 'I would like to receive news, offers and gifts from STK',
  reginfovalid: 'Please enter valid information above.',
  'cart.cart41': 'create account',
  'cart.cart42': 'Order Summary',
  'cart.cart43': 'Pack One – The Complete Cockpit and Hold',
  'cart.cart44': 'Price',
  'cart.cart45': 'Password (minimum 8 characters)',
  'cart.cart46': 'Fully assembled by master craftsmen',
  'cart.cart47': 'Cancel Build Service',
  'cart.cart48': 'Build Yourself Kit',
  paymentdisclaimer: 'By clicking Continue, you agree and consent to our ',
  paymentdisclaimer2: 'Terms & Conditions',
  paymentdisclaimer3: ' and ',
  paymentdisclaimer4: 'Privacy Policy.',
  paymentdepositdisclaimer:
    'I acknowledge and agree to pay the non-refundable deposit due for this order. I understand and agree to the ',
  paymentdepositdisclaimer2: 'terms and conditions including the cancellation policy.',
  mustagreedisclaimer: 'You must acknowledge and agree to the above in order to proceed.',
  honyangrecurring:
    'Please note: In order for us to process subscription orders, you must select/tick the "I agree to keep my credit card info in the system to save time for the next payment." checkbox on the upcoming payment page to enable recurring payments.',
  'cart.cart61': 'Payment Failed',
  'cart.cart62':
    'Your transaction could not be processed. No money has been taken from your account. Please try again. If the problem persists, please contact Customer Service.',
  'cart.cart63': 'Go back to try the payment again',
  'cart.cart64tc': ' ',
  'cart.cart64': ' per month thereafter',
  'cart.cart64b': 'per month',
  'cart.cart64c': ' ',
  'cart.cart64d': 'Per month thereafter',
  'cart.deposit': 'Non-refundable deposit',
  'cart.paynow': 'Payment due today',
  'cart.balancedue': 'Balance due upon shipment',
  'cart.discountamt': 'Discount amount',
  'cart.inputdiscount': 'Enter promo code',
  'cart.invaliddiscountcode': 'Invalid discount code',
  'cart.nevermiss': 'Never miss a Pack',
  'cart.freedelivery': 'Free delivery',
  'cart.nocommitment': 'No commitment',
  'cart.cancelanytime': 'Cancel at any time',
  'cart.starter01': 'Start building your movie prop replica with our lowest price offer',
  'cart.starter02':
    "If you decide to continue building your model, you'll be able to purchase the rest of the parts with no penalty - you'll pay the same prices in total as shown on the website today",
  'cart.starter03': "Plus you'll receive the free subscriber gifts when you complete the model",
  'cart.starter04':
    "Don't want to continue? Just do nothing. You don't need to cancel anything and we won't bill any further amounts",
  'cart.cart65': 'Province',
  'cart.cart66': 'Taiwan',
  'cart.cart67': 'Macau',
  'cart.cart68': 'The Chinese mainland',
  'cart.cart69': 'Buyer Contact Number',
  'cart.cart70': 'Recipient Contact Number',
  cartpaybytermcheckbox: 'Pay by installment',
  cartmonthlypayments: 'monthly payments',
  'cart.pack1disclaimer1': 'Your delivery of Pack One will include all the kit pieces required to build ',
  'cart.pack1disclaimer2': 'the complete Cockpit and Hold, as pictured above. ',
  'cart.pack1disclaimer3': 'To receive the kit pieces for the whole ',
  'cart.pack1disclaimer4': 'Millennium Falcon ',
  'cart.pack1disclaimer5': 'at special ',
  'cart.pack1disclaimer6': 'discounted prices, ',
  'cart.pack1disclaimer7': 'click here to order a subscription',
  'cart.depositdisclaimer0':
    'This is a built-to-order item. Returns or refunds are not possible, except in the case of defect.',
  'cart.depositdisclaimer1':
    'This order requires a deposit to reserve the item. Only the reservation deposit will be billed now. The balance payment is due once the item is ready to ship. Please note that reservation deposits are non-refundable - if you cancel your order before delivery your deposit will not be refunded. ',
  'cart.depositdisclaimer2': 'Please see the FAQ for further information.',
  'cart.receive_date': 'Estimated delivery: ',
  'cart.receive_date_1': ' to ',
  'cart.empty1': 'You have no items in your shopping cart.',
  'cart.empty2': ' ',
  'cart.empty3': 'Click here to continue shopping.',
  'cart.continueshopping': 'Continue Shopping',
  'login.login1': 'Please log in.',
  'login.login2': 'Use the email address for your order and the password you created.',
  'login.login3': 'Your email',
  'login.login4': 'Password',
  'login.login5': 'Log In',
  'login.login6': 'Forgot password?',
  notyouraccount: 'Not you? ',
  notyouraccount2: 'Click here to login to a different account.',
  'forgot.forgot1': 'Having trouble logging in?',
  'forgot.forgot2': 'Enter your email address below and reset password.',
  'forgot.forgot3': 'Your email',
  'forgot.forgot4': 'Reset password',
  'forgot.forgot5': 'Email Sent',
  'common.mainleft1': 'Profile',
  'common.mainleft2': 'Order History',
  'common.mainleft3': 'Log Out',
  profiledesc1:
    'Changes to your address and phone number will be registered and shown in your shopping cart the next time you place an order.',
  profiledesc2: 'If you wish to change the delivery address for an item you have already ordered, please contact us.',
  profiledesc3:
    'Subscribers can check the address their next delivery will be sent to on the Subscriptions page in their account area.',
  proile1: 'Personal Information',
  proile2: 'Your email',
  proile3: 'Password',
  proile4: 'Change Password',
  proile5: 'Current Password',
  proile6: 'New Password',
  proile7: 'Set New Password',
  'proile.firstname': 'First Name',
  'proile.lastname': 'Last Name',
  'proile.contactnum': 'Contact Number',
  'proile.address1': 'Please fill in full address',
  'proile.address2': 'Address Line 2',
  'proile.city': 'District',
  'proile.province': 'Province',
  'proile.country': 'Country',
  'proile.save': 'Save',
  order1: 'Order History',
  order2: 'Order',
  order3: 'Order Date',
  order4: 'Item Status',
  shippedqty: 'Shipped Qty: ',
  order5: 'Qty',
  order6: 'Price',
  order7:
    'If you have any questions about your orders, please contact our customer service at 0809-080-838. Thank you.',
  order: 'Set',
  'order.created': 'Created',
  'order.confirmed_order': 'Confirmed',
  'order.partially_shipped': 'Partially shipped',
  'order.shipped': 'Shipped',
  'order.payment_failed': 'Payment failed',
  ordervalidinfo: 'Please enter valid information above.',
  ordervalidemailconfirm: 'Your confirmation email address does not match your email address.',
  passwordlength: 'Your password must be at least 8 characters long.',
  invalidlogincredentials: 'The password and login email you entered do not match.',
  'order.ref': 'Order reference no.: ',
  'order.date': 'Order date: ',
  'order.status': 'Order status: ',
  'order.shipto': 'Ship to: ',
  'order.giftmsg': 'Gift message: ',
  'order.ordertotal': 'Order total: ',
  'order.paytotal': 'Payment total: ',
  'order.item': 'Item',
  'subscription.pack': 'Packs Shipped: ',
  'subscription.nextshipment': 'Next Shipment: ',
  'subscription.shipaddress': 'Shipment Address: ',
  'subscription.unsub': 'Cancel Subscription',
  'subscription.nounsub': 'I want to complete the model!',
  'subscription.plzunsub': 'Cancel my subscription',
  'subscription.sub': 'Subscription',
  'subscription.title': 'Subscriptions',
  'subscription.unsubtitle': 'Cancel Subscription',
  'subscription.unsubdesc': 'Are you sure you want to cancel this subscription?',
  alert1: 'The input box is not empty',
  alert2: 'not found',
  alert3: 'Single item orders must be placed separately from subscription orders.',
  alert4: 'Please confirm your subscription order first and then place a separate order for your single item purchase.',
  alert5: 'Subscription orders must be placed separately from single item orders.',
  alert6: 'Please confirm your single item order first and then place a separate order for your subscription purchase.',
  orderconflictnote:
    "We apologize for the inconvenience - this is a requirement of the third party secure payment provider to ensure charges are processed correctly. If you're having any problems placing your multiple item order, please contact us and we'll help.",
  orderconflictnote2:
    "If you're having any problems at all placing your order for multiple items, please contact us and we'll help.",
  alert7: 'success.',
  alert8: 'error.',
  subscriptionmorethanone1: 'Only one subscription order can be placed at a time.',
  subscriptionmorethanone2: 'Please confirm your subscription order first then place your second subscription order.',
  subscriptionmorethanone3:
    "We apologize for the inconvenience - this is a requirement of the third party secure payment provider to ensure charges are processed correctly. If you're having any problems placing your multiple item order, please contact us and we'll help.",
  cartremoveitemheader: 'Remove Item',
  cartremoveitem: 'Are you sure you want to remove this item from the shopping cart?',
  cartremoveconfirm: 'Remove',
  cartremovecancel: 'Cancel',
  alert9: 'Are you sure you want to remove this item from the shopping cart?',
  alert10: 'Email keeps consistent',
  alert11: 'The shopping cart is empty.',
  boxcountdown: 'SPECIAL OFFERS DURING COUNTDOWN TO LAUNCH',
  boxmobileday: 'DAY',
  boxmobilehour: 'HOUR',
  boxmobileminute: 'MINUTE',
  boxmobilesecond: 'SECOND',
  boxmobiledetailtitle: 'With Pack One you’ll get',
  boxmobiledetailcontent1: '• All the components to complete the Cockpit and Hold',
  boxmobiledetailcontent2: '• Including fully working lighting parts*',
  boxmobiledetailcontent3: '• Authentic, original English companion guide',
  boxmobiledetailcontent4: '• Official Chinese version companion  guide',
  boxmobiledetailcontent6: '• Easy to follow downloadable instruction sheets',
  boxmobiledetailcontent5: '• Access to comprehensive video build instructions',
  boxregularprice: 'Regular Price NT$',
  boxbuynow: 'BUY NOW NT$',
  boxfree: 'Free delivery within Taiwan',
  boxdo: 'Do not want to build?<br/>Let our master modellers do it for you!',
  boxbatteries: '*Batteries not included',
  boxpcday: 'Days',
  boxpchour: 'Hours',
  boxpcminute: 'Minutes',
  boxpcsecond: 'Seconds',
  guideevery: 'Every pack includes a companion guide ',
  guidefeaturing:
    'Featuring fascinating insights from the Star Wars archive explaining all about the Millennium Falcon and its capabilities, as well as the characters and their exciting missions. ',
  guideyoull: 'You’ll receive BOTH the original English content companion guide AND an official Chinese version.',
  headernavmenu: 'Menu',
  headernavlist1: 'VIDEO',
  headernavlist2: 'THE MODEL',
  headernavlist3: 'IN THE BOX',
  headernavlist4: 'BUILD SERVICE',
  headernavlist5: 'GALLERY',
  headernavlist6: 'INSTRUCTIONS',
  headernavlist7: 'FAQ',
  headernavlist8: 'ACCESSORIES',
  headernavlogin: 'Login',
  headernavmyaccount: 'My Account',
  headernavbuynow: 'BUY NOW',
  headeryou: 'Your cart is empty',
  conceptstoretitle: 'Visit the STK WORKSHOP exhibition in Taipei',
  conceptstoredesc1: 'See "',
  conceptstoredesc2: 'Build The Millennium Falcon',
  conceptstoredesc3: '", an official replica of the original movie prop from The Empire Strikes Back at',
  conceptstoredesc4: 'Wrong Gallery Taipei',
  conceptstoredesc5: ' ',
  conceptstoredesc6: 'from 8th December 2019 to 15th March 2020',
  conceptstoredesc7: 'Opening Hours:',
  conceptstoredesc8: 'Saturday: 13:00 ~ 21:00,',
  conceptstoredesc9: 'Sunday: 13:00 ~ 19:00,',
  conceptstoredesc10: 'Tuesday to Friday: 14:00 ~ 21:00,',
  conceptstoredesc11: 'Closed Monday and public holidays',
  homeOfficial: 'Official 1:1 replica of the original movie prop',
  homeOfficialMobileA: 'Official 1:1 replica of',
  homeOfficialMobileB: 'the original movie prop',
  homebuynow: 'BUY NOW',
  homelearn: 'LEARN MORE',
  homeScroll: 'Scroll down to see more',
  modelonewith: 'An authentic replica of the original movie prop from ',
  modelonewith2: 'The Empire Strikes Back',
  modelonewith3:
    ', with working lights and a motorized moving ramp. Hull parts are pre-finished and include removable panels to view the unique interior detail and stunning lighting effects.',
  imageDisclaimer: 'Images of the model are shown after assembly, painting and weathering.',
  modeltwosame: 'Same scale as ',
  modeltwothe: 'The Empire Strikes Back',
  modeltwomovie: ' movie prop',
  modelthreethe: 'The Finest Details',
  modelthreeincludes: 'Includes fine details replicated',
  modelthreejust: ' just like they appear in the movie',
  modelthreeremovable: 'Removable panels to',
  modelthreeview: ' view the interior',
  modelthree1removable: 'Removable panels to view the interior',
  modelthree1removableM1: 'Removable panels to view',
  modelthree1removableM2: 'the interior',
  modelfourdynamic: 'Dynamic Effects',
  modelfourlanding: 'Landing lights and motor-driven boarding ramp',
  modelfourprogrammed: 'Programmed lighting',
  modelfoursequence: ' sequence of the rear engine',
  modelfour1programmed: 'Programmed lighting sequence of the rear engine',
  modelfiveinterior: 'Interior Lighting',
  modelfivestunning: 'Stunning cockpit lighting effects',
  modelfiveworking: 'Working lights in corridors,',
  modelfivehold: ' hold and on consoles',
  modelsixpre: 'START BUILDING TODAY WITH',
  modelsixdisclaimer1: '*Build the Millennium Falcon ',
  modelsixdisclaimer2: 'is complete in 10 Packs. See below for further details.',
  modelsixpack: 'Pack One',
  modelsixpackast: '*',
  modelsixpackast2: ' - The Complete Cockpit and Hold',
  modelsixpack1: 'Pack One - The Cockpit and Hold',
  modelsixpack1star: 'Pack One',
  modelsixpack1starast: '*',
  modelsixpack1starast2: ' - The Cockpit and Hold',
  modelsixpack1a: 'Pack One',
  modelsixpack1b: 'The Cockpit and Hold',
  modelsixpack2: 'Subscribe to the series',
  modelsixregular: 'Regular Price NT$',
  modelsixbuy: 'BUY NOW NT$',
  servicelorem:
    "Whether you're looking for the ultimate professional finish for your model, or you simply don't have the time to build it yourself, just select one of the Build Service options and our master craftsmen will assemble and hand paint your model for you.",
  servicesimply: 'Simply select one of the STK Build Service options ',
  serviceall: 'All You Have To Do Is Click',
  shoppingspecial: 'Special Offers During Countdown To Launch',
  shoppingstart: 'Start building the iconic Millennium Falcon now',
  shoppingbuild: 'Subscribe to the series',
  shoppingregular: 'Regular Price NT$',
  shoppingadd: 'ADD TO CART NT$',
  shoppingthe: 'The Complete Millennium Falcon',
  shoppingtheM1: 'The Complete',
  shoppingtheM2: 'Millennium Falcon',
  shoppingsubscribe: 'Build yourself or let us do it for you',
  shoppingpack1head: 'Not ready for the complete model yet?',
  shoppingpack1head2: 'Start with these options',
  shoppingpack1: 'THE COCKPIT & MAIN HOLD',
  shopping10: 'Pack One at ',
  shopping10M: 'Pack One at ',
  shopping10a: 'then 9 monthly deliveries',
  shopping10a2: 'at ',
  shopping10a2b: ' NT$',
  shopping10a2c: ' NT$',
  shopping10a2d: ' each.',
  shopping10b: 'Save NT$',
  shopping10c: 'Cancel any time',
  shoppingCompleteKitRegPrice: 'Regular Price #1 NT$4480,',
  shoppingCompleteKitRegPrice2: '#2-10 NT$3990',
  shoppingmonth: 'Regular Price #1 NT$4480, #2-10 NT$3990',
  shoppingnow: 'SUBSCRIBE NOW',
  shoppingcraftsmen: 'The ULTIMATE model –',
  shoppingcraftsmen1: 'fully assembled and',
  shoppingcraftsmen2: 'hand-painted by',
  shoppingcraftsmen3: 'master craftsmen',
  fullkit01: 'The Full Kit',
  fullkit02: 'All the parts you need',
  fullkit03: 'to build the complete',
  fullkit04: 'Millennium Falcon',
  fullkit05: 'in a single delivery.',
  fullkit06: 'Save over NT$',
  fullkit07: 'FULL KIT NT$',
  trialpack01: 'Special Offer Starter Kit',
  trialpack02: 'Get the cockpit parts and',
  trialpack03: 'start assembling your movie prop replica for just',
  trialpack04: 'NT$',
  installmentavailable: 'Interest free payment plans',
  freegifttitle: 'FREE GIFT',
  freegiftp1:
    'Purchase The Complete Millennium Falcon either in kit form* or with our build service option and get four hand-painted scale figures as an exclusive free gift.',
  freegiftdisclaimer1: '*purchase all 10 Packs to qualify',
  freegiftdisclaimer2: 'Figures are not available for sale separately.',
  freegiftdisclaimer3: 'See the FAQ section for more information.',
  footernewsletterslogan: 'For the latest news, special offers, events, free gifts & more',
  footernewslettersloganA: 'For the latest news, special offers, events,',
  footernewslettersloganB: 'free gifts & more',
  footernewslettersloganm: 'For the latest news, special offers,',
  footernewslettersloganm1: 'events, free gifts & more',
  footernewslettersloganm2: ' ',
  footernewsletterslogan2: 'Sign up to our email newsletter',
  footernewsletteremail: 'Your email',
  footernewsletterbutton: 'Sign up',
  footernewsletterfineprint: "We'll never share your email with third parties.",
  footerquestions: 'Any questions?',
  footercontactphone: 'Call us on 0809-080-838',
  footercontactphone2: 'Lines are open 09:00-18:00 Mon-Fri except public holidays.',
  footercontactemail: 'Or email us at helpdesk@stk-workshop.tw',
  footercontactwhatsapp: 'Or on LINE@stkworkshop',
  footercontactonline: 'Find us online',
  downloadapp: 'Download the Build the Millennium Falcon App now!',
  shoppingwe: 'We are proud to present the award-winning, international hit product Build the Millennium Falcon. ',
  shoppingofficially: 'Officially licensed from Lucasfilm and Disney, in co-operation with De Agostini. ',
  shoppingavailable: 'Available in China ',
  shoppingexclusively: 'exclusively',
  shoppingfrom: ' from STK Workshop.',
  shoppingcustomers: 'Customers in other countries, please visit our',
  shoppingsite: ' International Site ',
  shoppingor: 'or contact us by email at ',
  shoppingcom: 'info@stkworkshop.com ',
  shopping2018: '2020 STK Workshop Limited. All Rights Reserved. ',
  shoppingterms: 'Terms & Conditions',
  shoppingLegal: 'Legal Notices',
  privacyPolicy: 'Privacy Policy',
  useOfWebsite: 'Notice on Use of Website',
  shoppingCompleteServiceDesc: 'The ULTIMATE model -',
  shoppingCompleteServiceDesc2: 'fully assembled and',
  shoppingCompleteServiceDesc3: 'hand-painted by',
  shoppingCompleteServiceDesc4: 'master craftsmen',
  shoppingmodel: 'The ULTIMATE model – ',
  shoppingfully: 'fully assembled and hand-painted by master craftsmen',
  shoppingPackOneKitDesc: 'All the parts you need to',
  shoppingPackOneKitDesc2: 'assemble the',
  shoppingPackOneKitDesc3: 'Cockpit and Hold',
  shoppingall: 'All the parts you need to assemble the Cockpit and Hold',
  shoppingall1: 'All the parts you need to assemble the',
  shoppingall2: 'Cockpit and Hold',
  shoppingPackOneServiceDesc: 'The Cockpit and Hold',
  shoppingPackOneServiceDesc2: 'delivered to you',
  shoppingPackOneServiceDesc3: 'fully assembled',
  shoppingthe1: 'The Cockpit and Hold delivered to you fully assembled',
  shoppingthe1a: 'The Cockpit and Hold delivered to you',
  shoppingthe1b: 'fully assembled',
  shoppingfree: 'Free delivery within Taiwan on all orders!',
  shoppingfree1: 'PLUS - ',
  shoppingfree2: 'order now to get ',
  shoppingfree3: 'FREE DELIVERY ',
  shoppingfree4: 'within Taiwan!',
  shoppingfree5: 'Free delivery within Taiwan',
  shoppingswapanchor: 'Click here',
  shoppingswap: ' to get The Complete Millennium Falcon instead.',
  shoppingswapanchor2: 'Click here',
  shoppingswap2: ' for Pack One - The Cockpit and Hold only.',
  accessoriestitle: 'Accessories',
  accessoriessection01: 'You may also like',
  accessoriessection01a: 'Our selection of display options. Custom made for your Millennium Falcon.',
  accessoriessection02: 'See All',
  accessoriessection03: 'Classic Plaque',
  accessoriessection04: 'Technical Plaque',
  accessoriessection05: 'Plaque Bundle',
  accessoriessection06: 'Metal Table Top Stand',
  accessoriessection07: 'Acrylic Floor Stand',
  accessoriessection07b: 'Wall Mount',
  accessoriessection08: 'Personalize (+NT$200)',
  accessoriessection09: 'Cancel personalization',
  accessoriessection10: 'Select your credit line',
  accessoriessection11: 'Assembled and Painted by',
  accessoriessection12: 'Modeller',
  accessoriessection13: 'Presented to',
  accessoriessection14: 'Commissioned by',
  accessoriessection15: '<None> There will be no text above your name',
  accessoriessection16: 'Enter the name to appear on the plaque',
  accessoriessection17: 'Maximum 20 English letters or 10 Chinese characters including spaces',
  accessoriessection18: 'Please check your spelling carefully!',
  accessoriessection19: 'Please select',
  accessoriessection20: 'Please enter the your name on the plaque!',
  accessoriessection21: 'Please select the credit line!',
  accessoriessection22: 'Personalize',
  accessoriessection23: 'Name on the plaque: ',
  accessoriessection24: 'Credit line: ',
  plaquetxtbanner01: 'Save NT$ 390 when you buy both plaques, ',
  plaquetxtbanner02: 'Add a metal display plaque for NT$ 790, or get the pair for NT$ 1580, ',
  learnmore: 'LEARN MORE',
  moredetail: 'MORE DETAIL',
  saleprice: 'Price: NT$',
  originalprice: 'Price: NT$',
  addtocart: 'Add to Cart',
  breadcrumbproduct: 'Product',
  currency: 'NT$',
  emf1: 'Headquarter:',
  emf2: 'STK Workshop Limited',
  emf3: 'Address: Flat 917, 9/F., Elite Industrial Centre, 883 Cheung Sha Wan Road, Cheung Sha Wan, Kowloon, Hong Kong',
  emf4: 'Tel: (852) 2744 7799',
  gallerytitle: 'Gallery',
  'instructions.title1a': 'Starter Kit',
  'instructions.title1': 'Pack One',
  'instructions.title2': 'Pack Two',
  'instructions.title3': 'Pack Three',
  'instructions.title4': 'Pack Four',
  'instructions.title5': 'Pack Five',
  'instructions.title6': 'Pack Six',
  'instructions.title7': 'Pack Seven',
  'instructions.title8': 'Pack Eight',
  'instructions.title9': 'Pack Nine',
  'instructions.title10': 'Pack Ten',
  'instructions.weathering': 'Painting & Weathering',
  comingsoon: 'Coming Soon',
  downloadinstructions: 'Download instructions (PDF)',
  'instructions.desc':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla condimentum condimentum sem ut ullamcorper. ',
  loadmore: 'LOAD MORE',
  aboutustitle: 'About Us',
  aboutusp1:
    'STK Workshop is a renowned producer of high-quality collectible models developed in co-operation with international brands and licensors for sale in Japan, Europe and the USA. We are proud to bring you a selection of our favourite products at stk-workshop.com, starting with ',
  aboutusp1a: 'Build The Millennium Falcon',
  aboutusp1b: '.',
  aboutusp2:
    'All products at stk-workshop.com are manufactured to the same specifications and quality standards as our export models. Our offers are tailored to give customers maximum choice – from simple, cost-effective build-yourself kit options to ultimate professionally assembled and hand-painted ready to display models. Our management, product development, operations and customer service teams are the same that service our customers in Japan and around the world.',
  aboutusp3:
    'Sign up to our email newsletter for the latest news about new product announcements, special events, competitions and more. Come join us on our mission to bring the most innovative, interesting and impressive collectible models to Hong Kong, China, Asia and the world.',
  tc: 'Terms & Conditions ',
  tc01:
    'This page and the separate sections of the website referred to are to advise you of the terms and conditions of use (“Terms”)             under             which you may use our website www.stk-workshop.com (the “Website”). ',
  tc02:
    'We suggest you read them carefully before you use the Website or place any order at the Website.  You must confirm that you have          read and          accept these terms before you place any order at the Website.',
  tc03:
    'By using our Website, you indicate that you understand and accept these Terms, Notice of Use of Website, Privacy Policy and information included in the FAQ section of the Website and that you agree to abide by them. If you do not              agree to these             Terms, please refrain from using our Website. ',
  tc04:
    'These Terms may be updated from time to time. Such changes will not affect any orders you have previously placed with us. ',
  tc10: '1. Information about us ',
  tc11:
    'The Website is operated by STK Workshop (“STK”, “we” or “us”). We are registered in Hong Kong have our registered office              address           at: 3F Wah Shing Industrial Building, 18 Cheung Shun Street, Lai Chi Kok, Kowloon.',
  tc20: '2. Access and Use of the Website',
  tc21: 'Please refer to the FAQ, Notice on Use of Website and Privacy Policy.',
  tc22:
    '2.1 If you choose, or you are provided with a user identification code, password or any other piece of information as part of our         security          procedures, you must treat such information as confidential and you must not disclose it to any third party and you              should inform us             immediately if you have any reason to believe that your password has become known to anyone else, or if the              password is being, or is           likely to be, used in an unauthorised manner. You agree to accept responsibility for all activities that         occur under your account or                  password. We have the right to disable any user identification code or password, whether chosen by            you or allocated by us, at any time,          if in our opinion you have failed to comply with any of the provisions of these Terms. ',
  tc30: '3. Your Order ',
  tc31:
    '3.1 Only persons aged 18 years and over, who are legally entitled to do so are permitted to place orders on the Website.                Therefore,       by placing an order, you confirm that (i) you are at least 18 years old; and (ii) you agree to be bound by these             Terms.',
  tc32:
    '3.2 Products may be ordered by clicking on the items you wish to purchase and then following the prompts that will appear               on-screen.       You will have the opportunity to check and if necessary correct any input errors in your order up until the point at         which you submit your          order to us.',
  tc33:
    '3.3 Your order constitutes an offer to us to buy a Product. All orders are subject to acceptance by us. We are not obliged to           accept            your order and may, in our discretion, decline to accept any order. Where we accept your order, we will confirm such             acceptance to you         by sending you an email that confirms that we have accepted your order (Order Confirmation). The contract             between you and us in                relation        to the products ordered (Contract) will only be formed when we send you the Order Confirmation.          We will send you a further       email to notify        you when your goods have been dispatched. ',
  tc34:
    '3.4 The Contract will relate only to those products whose dispatch we have confirmed in the Order Confirmation. We will not be          obliged to        supply any other products which may have been part of your order until the dispatch of such products has been                confirmed in a                separate Order Confirmation. ',
  tc40: '4. Delivery ',

  tc41:
    '4.1 Your order will be fulfilled by the delivery date set out in the Order Confirmation or, if no delivery date is specified, then within 30 days       of the date of the Order Confirmation, unless there are exceptional circumstances. ',

  tc42:
    '4.2 Your order will be delivered to the delivery address you specify when placing your order. Products comprised within the             same order        cannot be delivered to different addresses. ',
  tc50: '5. Territories',
  tc51:
    '5.1 We will deliver orders to addresses in a limited number of countries. Please consult the FAQ sections of the website for            the latest        information. We will not deliver to nor assist in facilitating delivery to any countries other than those listed.',
  tc60: '6. Risk and title ',
  tc61:
    '6.1 Products ordered will be at your risk from the time of delivery. Ownership of the products ordered will also pass to you on         delivery,         provided full payment of all sums due in respect of the products, including any delivery charges, has been                    received. ',
  tc70: '7. Cancellation and Returns',
  tc71:
    '7.1 Except in relation to certain products set out below, you may cancel your Contract at any time before your order is delivered and within 15           working days of receiving delivery from us of your individual pack order or the first pack of your subscription order.',
  tc72:
    '7.2 If you wish to cancel a subscription order you can may do so by informing us up to 15 working days before the dispatch of           your next         scheduled delivery.',
  tc73:
    '7.3 In the event you wish to cancel a Contract, you must clearly inform us, either via the Website or in writing by email                                 to helpdesk@stk-workshop.com, giving us your name, address and order reference.  ',
  tc74: '7.4 You will not have any right to cancel a Contract for the supply of any build services. ',
  tc75:
    '7.5 You have a legal obligation to take reasonable care of the products while they are in your possession. If you fail to               comply            with this obligation, we may have a right to claim the cost of any deterioration from you.',
  tc76:
    '7.6 If you cancel a Contract between us during the period specified in paragraph 7.1, we will process any refund due to you as          soon as           possible and, in any case, within 30 days of the day you have given notice of your cancellation. We will refund the             price of the               product in full, less any deduction to reflect any reduction in the value of the item if this has been caused by           your inappropriate              handling of them. You will be responsible for the cost of returning the products to us.',
  tc80: '8. Prices',
  tc81:
    '8.1 The price of any products will be as quoted on the Website from time to time. Prices and delivery costs are liable to change at any time, but changes will not affect orders in respect of which we have already sent you an Order Confirmation.',
  tc82:
    "8.2 We will check prices before accepting your order but if we find that the product was incorrectly priced and the product's correct price at your          order date is less than our stated price at your order date, we will charge the lower amount. If the product's correct price at your order date is          higher than the price stated, we will contact you for your instructions before we accept your order. If we accept and process your order where a          pricing error is obvious and unmistakeable and could reasonably have been recognised by you as a mispricing, we may end the contract, refund you          any sums you have paid and require the return of any goods provided to you. ",
  tc83:
    '8.3 Payments for orders must be received in full before the product is shipped. Payments may be made only by the methods                indicated       in the FAQ section of the Website. ',
  tc90: '9. Faulty products',
  tc91:
    '9.1 If any Product you order is damaged or defective when delivered to you, the price of the Product shall be refunded to you           in full,        including the cost of delivery. If you believe a Product was delivered damaged or faulty, you must inform us in                writing, giving us         your name, address and order reference. Nothing in this section affects your legal right.',
  tc92:
    '9.2 To return a faulty Product, you must package and return the item to us for inspection. We advise that you return the Product to us via some form of certified mail. Please contact us to find out our return address and how to send your goods back to us for a refund.',
  tc93: '9.3 In the event that a Product is found to be faulty by us, we will either: ',
  tc93a:
    '(a) subject to availability, replace your Product free of charge and refund your return delivery costs in full; or (b) if we are unable to               replace your Product, provide you with a full refund including your return delivery costs for returning the faulty Product to us.',
  tc94:
    '9.4 In order for us to refund the return delivery costs to you, please provide us with copies of receipts to evidence your              postage         costs (including cost of postage materials) to the address above. If a Product is not found to be faulty by us, we will         not refund your             delivery costs for returning the Product to us. ',
  tc95:
    '9.5 In the event that the reason you consider the product to be defective is due to a missing part(s), you agree that should we         supply            such part(s) to you to the claim will be considered as resolved to your satisfaction and no return shall be necessary            nor any refund           due. ',
  tc100: '10. Product information',
  tc101:
    '10.1 Whilst we have taken reasonable steps to depict products as accurately as possible through the photographs and other               images         featured on the Website, the detailing you see on-screen will depend on your device settings and, as such, may not               exactly reflect the        actual detailing of a Product when you receive it.',
  tc110: '11. Intellectual property rights',
  tc111:
    '11.1 All intellectual property rights in any content on the Website (including text, graphics, software, photographs and other         images,          videos, sound, trade marks and logos) are owned by us or our licensors.',
  tc112:
    '11.2 If you print off, copy or download any part of our site in breach of these Terms, you must not remove any copyright,              trade mark       or other intellectual property right notice(s) from the content.',
  tc113:
    '11.3 Except to the extent expressly set out in these Terms, you are not being given any rights in respect of any intellectual          property        rights owned by us or our licensors. You do not acquire any ownership of, or other rights in relation to, any such             intellectual property        rights by downloading any content from the Website or otherwise.',
  tc120: '12. Our Liability',
  tc121: '12.1 Nothing in these Terms and conditions shall limit or exclude our liability to you: ',
  tc121a: '(a) for death or personal injury caused by our negligence; ',
  tc121b: '(b) for fraudulent misrepresentation; ',
  tc121c:
    '(c) for breach by us of any term to the effect that we have the right to sell and supply the products to you; or',
  tc121d: '(d) for any other liability that may not, under Hong Kong law, be limited or excluded.',
  tc122:
    '12.2 Subject to paragraph 12.1, in no event shall we be liable to you for any business losses and any liability we do have for         losses you        suffer arising from any Contract shall not exceed the purchase price of the relevant products (together with any             ancillary charges, for         example for delivery) and is limited to losses that were reasonably foreseeable. Losses are foreseeable          where, at the time your order is         accepted by us, they were reasonably foreseeable as being a likely consequence of any breach           by us of the agreement between us and you.',
  tc123:
    '12.3 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under         any               Contract that is caused by events outside our reasonable control.',
  tc124:
    '12.4 We only supply the products for domestic and private use. If you use the products for any commercial, business or re-sale         purpose we        will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business           opportunity. ',
  tc130: '13. External links',
  tc131:
    '13.1 Our Website may include links to other websites. We may include these to provide you with access to information and               services          that you might find useful or interesting. We have no control over and are not responsible for the content of these            other websites or            for anything provided by them, and do not guarantee that they will be available at any given time. The fact           that we include links to            such external sites does not imply any endorsement of, or association with, their operators.',
  tc140: '14. General',
  tc141: '14.1 You may not transfer or assign any or all of your rights or obligations under any Contract.',
  tc142:
    '14.2 All notices given by you to us must be given in writing to the address set out at the end of these Terms. We may give              notice to        you at either the email or postal address you provide to us when placing an order.',
  tc143: '14.3 If we fail to enforce any of our rights, that does not result in a waiver of that right.',
  tc144:
    '14.4 If any provision of these Terms is found to be unenforceable, all other provisions will remain unaffected.',
  tc145: '14.5 These Terms may not be varied except with our express written consent.',
  tc146:
    '14.6 These Terms and any document expressly referred to in them represent the entire agreement between us in relation to the           subject           matter of any Contract. ',
  tc147:
    '14.7 These Terms are written in the English Language and may be translated into other languages. In the event of inconsistency between the English version and the translated version of this Notice, the English version shall prevail',

  nrdtctitle: 'Terms & Conditions for Non-refundable Deposit',
  nrdtctitle2: 'ORDERS REQUIRING A DEPOSIT',
  nrddesc:
    'Some items involving hand assembly and painting will require a reservation deposit payable at the time the order is placed.',
  nrdtcpayment: 'PAYMENT',
  nrdtc10:
    'When you confirm your order for an item requiring a non-refundable deposit (NRD) you will be billed the amount shown on the day you confirm your order.',
  nrdtc11: 'Upon receiving your payment we will reserve your item and send you an Order Confirmation email.',
  nrdtc12: 'The balance payment due will be automatically billed when your order is shipped.',
  nrdtc13: ' ',
  nrdtcshipment: 'SHIPMENT',
  nrdtc20:
    'The estimated shipment date is as notified to you when you placed your order and stated in your Order Confirmation email.',
  nrdtc21:
    'The shipment date is estimated and may change. If we are able to ship earlier than the date originally notified to you, we will contact you before shipment and give you the option of receiving earlier. The actual shipment date may be delayed to ensure your hand-assembled and painted item leaves our warehouse in perfect condition.',
  nrdtc22:
    'As soon as your item is ready to ship we will contact you to arrange a suitable delivery date. Please ensure you are available at that time to receive delivery. If we are unable to deliver your order to you on the date you requested, extra charges for returning to the warehouse, storage and subsequent transportation to you may apply.',
  nrdtccancellation: 'CANCELLATION',
  nrdtc30: 'If you decide to cancel your reservation the non-refundable deposit will not be refunded.',
  nrdtc31: 'NRDs cannot be transferred to any other order.',
  nrdtc32:
    'If we are unable to receive the balance payment at the time it is due, we will attempt to contact you to make alternative arrangements to collect the balance. If we are unable to contact you or if you are unable to complete the balance payment your order will be considered to be cancelled and your NRD will not be refunded.',
  nrdtc33:
    'In the unlikely event that your item’s delivery date has been delayed by more than 90 days beyond the original estimated shipment date you may contact us to cancel your order and request your NRD to be refunded.',

  faq: 'FAQ',
  faqpreorder: 'PRE-ORDER',
  faqpreorder1: 'STK Workshop is now accepting orders for ',
  faqpreorder1a: 'Build The Millennium Falcon',
  faqpreorder1b: '.',
  faqpreorder2: 'Manufacturing is underway and current expected delivery dates for orders are;',
  faqpreorder3: 'Pack One for Subscribers: late April 2019',
  faqpreorder4: 'Pack One Kits: early May 2019',
  faqpreorder5: 'Pack One with STK Workshop Build Service: late May 2019',
  faqpreorder6: 'Complete Model with STK Workshop Build Service: October 2019',
  faqpreorder7: 'Once the pre-order period ends, orders will be processed as described below.',
  faqorder: 'ORDERS',
  faq10: 'How to Order',
  faq11:
    'All items are available for purchase exclusively at stk-workshop.com. We do not supply to wholesalers, model stores or third party e-commerce sites.',
  faq20: 'When will I receive my order?',
  faq21:
    'When the goods are in stock you can normally expect to receive your order in 1-3 days. Out of stock items or orders that require custom assembly or painting before dispatch will require a longer wait. During the order process, the expected delivery date will be clearly indicated before you confirm your order.',
  faq30: 'How can I check my order status?',
  faq31:
    'Once you have completed your order successfully you will receive a confirmation email with full details of your order. If you did not receive your order confirmation email we recommend that you check your ‘Bulk’ or ‘Spam’ folders. If you are unable to find your order confirmation email please contact us so we can determine if your order was correctly authorised.',
  faq32a: 'If you have created an account at stk-workshop.com, the status of your order will be displayed as follows; ',
  faq32b: 'Confirmed – your order has been successfully received and is being processed. ',
  faq32c:
    'Shipped – your order has been collected from our warehouse by SF Express and is now on the way to you. Your tracking number will also be displayed. If your order includes multiple items we’ll normally ship any that are in stock first and any temporarily out of stock items will follow later. In this case your order status will show as “Partially Shipped”.',
  faq32d:
    'Next Delivery – if you have placed a Subscription order the planned date of your next dispatch will be shown here.',
  faq32e:
    'We recommend creating an account because it also means you’ll get the best offers and most personalised service.',
  faq40: 'Can I see the product displayed somewhere?',
  faq41:
    'Yes. The products are on display at the STK Workshop concept store in Hong Kong. And right now you can also experience an amazing life-size replica of the main hold from the Millennium Falcon, including the famous bench seat, game table and Han Solo’s engineering station.',
  faq41a:
    'Please visit us at D2 Place One, 9 Cheung Yee Street, Lai Chi Kok, Kowloon, Hong Kong. We’re open every day from noon to 9pm.',
  faq41b: ' ',
  faq50: 'Can I buy in a physical store?',
  faq51:
    'All items are available for purchase exclusively at stk-workshop.com. However we do offer the option to pick up certain items at the STK Workshop concept store so during your visit you can simply place your order online, select the option to pick up in-store and take your order home with you right away – no waiting for it to be delivered.',
  faq60: 'What is STK Build Service?',
  faq61:
    'Wherever you see the STK Workshop Build Service logo we can provide a full assembly and hand-painting service. Whether you want the ultimate               professional version of the product or you just don’t have time to assemble yourself, simply select the Build Service option and our master                modellers will assemble and paint for you. All you have to do is click. ',
  faq62:
    'Please note that STK Workshop Build Service is not available for every product - only where the option is clearly indicated by the orange STK              Workshop Build Service logo.',
  faq70: 'Can I buy all the packs now? Do I have to wait for 10 months to build my model?',
  faq71:
    'At this time we are only shipping each pack on a monthly release schedule. Customers who order the complete STK Workshop Build Service option will be the first to receive the full model. Thereafter we may offer all packs in one delivery. Sign-up to our email newsletter and follow us on social media to be notified should full kits become available.',
  faq80: 'Do you accept international orders?',
  faq81:
    'At this time we are accepting orders only for delivery to Hong Kong and Macau addresses. Send an email to international@stk-workshop.com to let us know where you are and we’ll notify you when we can ship to your country – you’ll also get access to the best early-bird offers. Feel free to share the stk-workshop website or product information with your friends and groups – the more requests we receive from customers in your country the sooner we’re likely to be able to service.',
  faq90: 'Can I order by phone?',
  faq91:
    'At this time we are not accepting orders by phone. Please don’t reveal your bank or credit card information to anyone during any phone call to             STK Workshop. ',
  faq100: 'Can I order by email?',
  faq101:
    'At this time we are not accepting orders by email. Please don’t reveal your bank or credit card information in any email to STK Workshop. ',
  faq110: 'What happens if the item I want is out of stock? ',
  faq111:
    'We make every effort to ensure stocks of every product are available for immediate dispatch but at times a particular item may be temporarily             out of stock. Whenever this happens you will be clearly notified before you place your order. You’ll still be able place an order to ensure you            receive your delivery as soon as stocks are available.',
  faq120: 'Can I order replacement parts?',
  faq121:
    'Although we don’t sell items individually, we understand that sometimes parts can get lost or broken and we want you to be able to complete your           model successfully. Contact our customer service team on 2133 1661 or at helpdesk@stk-workshop.com and we’ll do our best to help.',
  faq130: 'What if I want to cancel my order?',
  faq131: 'Contact our customer service team as soon as possible on 2133 1661 or at helpdesk@stk-workshop.com. ',
  faq132: 'Please note that;',
  faq133:
    '- once an order has been processed for delivery it will not be possible to cancel. The order will be delivered as planned. Please refer to the section on RETURNS & EXCHANGES.',
  faq134: '- we cannot accept cancellations or refunds for STK Build Service products.',
  faq135:
    'If you are a Subscriber you can cancel your subscription up to 15 working days before the dispatch of your next scheduled delivery. Please visit          your account page to notify us of your cancellation. Please note that any special gifts for Subscribers that you have not already received will            also be automatically cancelled.',

  faqsubscriptions: 'SUBSCRIPTIONS',
  faq140: 'What is a Subscription?',
  faq141:
    'Placing a Subscription order simply lets us know that you want to order and receive the next pack of model parts each month at a special                  discounted price. Your recurring order will be charged and fulfilled on a monthly schedule and when the last pack of parts is delivered along              with any special subscriber-only gifts, your Subscription will automatically stop.',
  faq142: 'You can cancel any time before that – please see the Cancelling a Subscription article for full details.',
  faq150: 'What are the benefits of a Subscription?',
  faq151:
    'Ordering a Subscription is the best way to save money, time and trouble. You get the best offers and gifts, and priority delivery. All with no commitment as you can cancel anytime. With a Subscription order you will;',
  faq1510: '	•	save more than 10% compared to the cost of purchasing each pack individually',
  faq1511: '	•	make no long-term advance payment – you are charged monthly before the next pack is shipped',
  faq1512: '	•	receive each subsequent pack automatically – no need to place another order every month ',
  faq1513: '	•	receive delivery first – before those purchasing individually',
  faq1514: '	•	have delivery priority in case of any temporary stock shortage ',
  faq1515: '	•	receive special free gifts only available to Subscribers ',
  faq1516:
    '	•	make no long-term commitment - you can cancel any time. (Please see the Cancelling a Subscription article for full details)',
  faq160: 'Do I have to sign a contract?',
  faq161:
    'No. Placing a Subscription order simply lets us know that you want to receive the next pack of model parts each month. Your recurring order will be fulfilled and when the last pack of parts is delivered along with any special Subscriber-only gifts, your Subscription will automatically stop.',
  faq162: 'And you can cancel any time – please see the Cancelling a Subscription article for full details.',
  faq170: 'When will my card/account be charged?',
  faq171:
    'The first pack in your Subscription will be billed at the time you place your order. Each subsequent payment will occur 2-3 days before the next pack is due for dispatch. The exact date is dependent on when you placed your original order and notified to you in your shipment notice and account area, but each payment/dispatch will be approximately 30 days after the last.',
  faq180: 'Will the price change during my Subscription?',
  faq181:
    'The basic price of the product you purchase directly from STK Workshop as well as the delivery cost will be the same every month until the end of your Subscription. However, should the statutory rate of VAT change between your order date and the date we supply the product the final price of your product may change. We may increase the price to reflect the VAT increase if you have not paid for the product. If you have already paid in full for the product, then the price will not change.',
  faq190: 'Will the shipping be free for every pack?',
  faq191:
    'Yes, if you start a subscription at this time, shipping will be free for each subsequent pack in your subscription until it completes.',
  faq200: 'Can I order more than one Subscription?',
  faq201:
    'You can order as many Subscriptions you wish and have each delivered to the same or separate addresses. We only ask that you complete individual orders for each subscription. It will not be possible to set a quantity of 2 or more subscriptions in the same shopping cart even if delivery is to the same address. Shipping is free of charge so multiple Subscription orders do not affect your monthly delivery cost or your statutory rights.',
  faq210: 'Can I temporarily suspend my Subscription?',
  faq211:
    'Unfortunately we are unable to temporarily suspend a Subscription. If you cancel your Subscription you may not be able to restart from the same number and price per pack at a later date. However, if you do have to cancel and decide later to restart please contact us on 2133 1661 or at helpdesk@stk-workshop.com and we will do our best to help.',
  faq220: 'Cancelling a Subscription',
  faq221:
    'We’re sorry you don’t want to complete your model and we hope you’ll let us know why at your account page or by contacting us on 2133 1661 or at helpdesk@stk-workshop.com',
  faq222:
    'You can cancel your Subscription up to 15 working days before the dispatch of your next scheduled delivery. ',
  faq223:
    'Please visit your account page to notify us of your cancellation. Please note that any special gifts for Subscribers that you have not already received will also be automatically cancelled. No further deliveries will be dispatched and you will not be charged any further amount.',
  faq224: 'No amounts for orders already delivered or processed will be refunded.',

  faqbilling: 'BILLING & PAYMENT',
  faq230: 'Accepted forms of payment',
  faq231:
    'We accept Visa, Mastercard and Union Pay. We also accept Alipay and WeChat Pay but currently only for China accounts. (Alipay HK and WeChat Pay HK and those of other countries are not applicable).',
  faq240: 'When will my card/account be charged?',
  faq241:
    'You will be billed at the time you place your order. Once we receive confirmation that your payment has been successful your order will be processed immediately. ',
  faq242:
    'For Subscribers, each subsequent payment will occur 2-3 days before the next pack is due for dispatch. The exact date is dependent on when you placed your original order and notified to you in your shipment notice and account area, but each payment/dispatch will be approximately 30 days after the last.',
  faq250: 'How do I update my billing details?',
  faq251:
    'For your security and peace of mind, STK Workshop does not see or store your payment details. All payments are securely processed by AsiaPay Limited. You will be required to input your payment details each time you place an order. ',
  faq252:
    'Subscribers only need to place their order one time and AsiaPay will process recurring payments automatically. If you need to update your details for your recurring Subscription payments please contact us on 2133 1661 or at helpdesk@stk-workshop.com with your order reference number and we’ll give you instructions how to update billing details. Please don’t share any of your payment/card information by email or during a phone call.',
  faq260: 'What if my credit card expires?',
  faq261:
    'If your credit card has expired please contact us on 2133 1661 or at helpdesk@stk-workshop.com and let us know your order reference number and we’ll give you instructions how to update billing details. Please don’t share any of your payment/card information by email or during a phone call.',
  faq270: 'Can I change the payment charge date?',
  faq271:
    'Unfortunately we cannot normally change a payment date as all payments are systemized and handled securely but please contact us on 2133 1661 or at helpdesk@stk-workshop.com and we’ll do our best to help.',
  faq280: 'Is your website secure?',
  faq281:
    'Your personal information submitted online at stk-workshop.com is completely secure. Our best-in-class providers protect the information you send us with secure forms and systems.',
  faq282:
    'Your payment details are not seen or stored by STK Workshop. For your security and peace of mind, all payments are securely processed by AsiaPay Limited.',

  faqshipping: 'SHIPPING',
  faq290: 'How will my order be shipped?',
  faq291:
    'After your order is confirmed our warehouse will immediately process for dispatch and delivery to the respective SF Express hub. SF Express will deliver to the address you specified when you ordered.',
  faq300: 'What are the shipping costs?',
  faq301: 'Nothing. All our products currently include free delivery to addresses in Hong Kong and Macau.',
  faq310: 'When will my order be shipped? When will my order arrive?',
  faq311:
    'In most cases your order will be processed within hours of you placing it and should leave the STK Workshop warehouse within a day of receipt. Products that require custom assembly or painting may require a longer period before dispatch. Out of stock items’ dispatch dates will vary case by case but the expected delivery date will be clearly indicated before you confirm your order. Pre-Order items will show their future estimated arrival date.',
  faq320: 'When will my order arrive?',
  faq321:
    'When the goods are in stock you can normally expect to receive your order in 1-3 days. Out of stock items or product that require custom assembly or painting before dispatch will require a longer wait. During the order process, the expected delivery date will be clearly indicated before you confirm your order. ',
  faq330: 'How do I track my order?',
  faq331: 'You can see the current status of your order in your account area at stk-workshop.com.',
  faq332:
    'Once your SF Express tracking number has been issued you’ll receive an email and link from us and you can track your shipment at the SF Express website.',
  faq333:
    'If you did not create an account please wait for the email with your tracking number. And while you wait, why not create an account now so you can enjoy earlier status updates and much more. ',
  faq340: 'Can I ship to a different address?',
  faq341:
    'Yes. During the checkout process you can provide one address for delivery and another for billing. So you can ship to your office or to a friend instead of to your home if you wish. We only ask that you place one order for each delivery address. It will not be possible to order multiple items to be sent to different addresses in the same order.',
  faq350: 'Can I ship to a PO Box?',
  faq351:
    'As SF Express cannot ship to a Post Office box, STK Workshop will not deliver to a PO Box address. If you enter a PO Box address during check out you will be asked to advise an alternative address for delivery.',
  faq360: 'Can I ship as a gift?',
  faq361:
    'Absolutely. We’re happy to help you make someone’s day by sending them such a special gift. During the checkout process you will be able to provide one address for delivery and another for billing. You can even include a short personalised note. The recipient will NOT be notified about the cost of the purchase. ',
  faq362:
    'Note that the delivery details and tracking number will be sent only to the buyer’s email address so the delivery will be a surprise to the recipient unless you pass on the delivery information. So please be aware that extra charges may apply if the goods cannot be delivered in a reasonable time – see the article What happens if I’m not at home when the delivery arrives? ',
  faq370: 'Can I collect my package myself?',
  faq371:
    'Certain items are available for pick-up at the STK Workshop concept store at Lai Chi Kok, Hong Kong. Simply select this option during the check-out process and we’ll notify you as soon as your goods are ready for collection.',
  faq380: 'I gave the wrong delivery address with my order.',
  faq381:
    'Please contact us on 2133 1661 or at helpdesk@stk-workshop.com immediately and we will do our best to help. Be sure to go to your account area at stk-workshop.com and update your delivery address details as soon as possible.',
  faq390: 'Can I change my delivery address?',
  faq391:
    'If you already placed your order please contact us on 2133 1661 or at helpdesk@stk-workshop.com immediately and we will do our best to help. Be sure to go to your account area at stk-workshop.com and update your delivery address details as soon as possible.',
  faq392:
    'If you are a subscriber just go to your account area at stk.com and update your delivery address details in good time before your next shipment is due. Contact us on 2133 1661 or at helpdesk@stk-workshop.com if you need to be sure about your next delivery getting to your new address.',
  faq400: 'What happens if I’m not at home when the delivery arrives?',
  faq401:
    "If SF Express has attempted to deliver a parcel that is too large to fit through a letter box and there is nowhere suitable to leave it, they will return it to the local SF Express office. Please look for a 'We tried to deliver' notice which should have the relevant contact details on it. Contact your local SF Express office with those details to arrange a convenient time for delivery.",
  faq402: 'You can also track your package at the SF Express website.',
  faq403:
    'If SF Express are unable to deliver to you because you have not arranged a delivery date with them within a reasonable period of the initial attempt to deliver, or to collect from the local depot yourself, we may charge you for storage costs and any further delivery costs while we will try to contact you to make new arrangements. If we remain unable to contact you or to arrange a suitable delivery or collection we may terminate the agreement, refund to you any amount you may have paid in advance for goods we are yet to dispatch and deduct or charge you appropriate compensation for costs incurred as a result of you not allowing us to deliver the products to you.',
  faq410: 'Additional Shipping Fees',
  faq411:
    'STK Workshop will not be responsible for costs resulting from orders shipped to out of date or incorrect address. Customers will be charged for any return shipping fees due to incorrect delivery address information.',
  faq420: 'Which countries do you deliver to? Do you ship internationally?',
  faq421:
    'At this time we are accepting orders only for delivery to Hong Kong and Macau addresses. Send an email to international@stk-workshop.com to let us know where you are we’ll notify you when we can ship to your country – you’ll also get access to the best early-bird offers. Feel free to share the stk-workshop website or product information with your friends and groups – the more requests we receive from customers in your country the sooner we’re likely to be able to service.',

  faqreturns: 'RETURNS AND EXCHANGES',
  faq430: 'What if I want to return my item? What is your Returns Policy?',
  faq431:
    'STK Workshop customers are covered by a three week (15 working day) return window from the date of delivery for any pack purchased individually or the first pack of any subscription order. Simply return your order within 15 working days of receiving the delivery from us, with all elements included, all packaging intact and undamaged. Once the goods are received at our warehouse and the condition verified we will refund your purchase price less any deduction to reflect any reduction in the value of the item if this has been caused by your inappropriate handling of them, i.e. in a way that would not be permitted in a store, such as opening or breaking internal package seals, damage to or removal of any component items or external packaging, etc ',
  faq432:
    'Contact us on 2133 1661 or at helpdesk@stk-workshop.com so we can provide you with the correct return address and a return reference number. ',
  faq433:
    'You will need to pay the shipping fee to return the product to us except in the case of defective items. Please refer to the Who pays the return shipping fees? article for more details.',
  faq434:
    'Please note that STK Workshop will only accept returns as described above for orders originally placed at stk-workshop.com, at STK Workshop owned stores or at promotional events conducted by STK Workshop. If you ordered from other sources, please consult the returns policy from the seller/retailer you purchased it from. If the seller is unable to assist you, contact us on 2133 1661 or at helpdesk@stk-workshop.com and we will do our best to help. However, in any event STK Workshop is unable to issue any refunds for products that were not purchased directly from STK Workshop.',
  faq435:
    'We cannot accept returns nor offer refunds for the second or subsequent packs of a subscription order. If you wish to cancel your subscription to stop receiving future packs, please refer to the Cancelling a Subscription article. ',
  faq436: 'We cannot accept returns or refunds for STK Build Service products.',
  faq437:
    'Any refund amount will be for the benefit of the original purchaser only. STK Workshop will not refund any amount to anyone other than the original purchaser.',
  faq438:
    'All approved refunds will be processed as quickly as possible. STK Workshop will determine the appropriate payment method of refund – which may not be the same as the original method of payment and may include a personal cheque for the benefit of the original purchaser sent to the billing address registered.',
  faq440: 'What if my order is defective, incomplete or damaged? ',
  faq441:
    'STK Workshop products are subject to the strictest standards of quality control – every order is individually inspected before dispatch and will in each case match the original versions approved by licensors. Many component parts are hand-finished or painted, which results in small differences between examples of the same piece received by different customers or as pictured on the STK Workshop website or promotional materials. Such variations are not considered defects.',
  faq442:
    'However, in the rare case your order arrives with defective, missing or damaged parts we will provide replacements. ',
  faq443:
    'Contact us at on 2133 1661 or at helpdesk@stk-workshop.com if you believe your order has arrived in a damaged or defective condition and we’ll discuss with you the appropriate arrangements to receive a replacement and how to have any damaged or defective parts returned to us. ',
  faq444:
    'You will not need to pay the shipping fee to return defective product to us or to receive your replacement item. ',
  faq445:
    'Please note that STK Workshop will only accept returns as described above for orders originally placed at stk-workshop.com, at STK Workshop owned stores or at promotional events conducted by STK Workshop. If you ordered from other sources, please consult the seller/retailer you purchased from. ',
  faq446:
    'Any refund amount will be for the benefit of the original purchaser only. STK Workshop will not refund any amount to anyone other than the original purchaser.',
  faq447:
    'In certain circumstances we may to ask you to return the full pack at our cost and refund your purchase. In such cases, we will discuss with you the appropriate arrangements. STK Workshop will determine the appropriate payment method of any refund – which may not be the same as the original method of payment and may include a personal cheque for the benefit of the original purchaser sent to the billing address registered.',
  faq448:
    'Cosmetic damage to packaging including small creases or bends or dents at the corners are not considered as defects and will not be eligible for replacement. Outer cartons (brown corrugated board boxes) are for protective purposes only and any kind of damage to them is not considered as a defect and will not be eligible for replacement.',
  faq450: 'Who pays the return shipping fees?',
  faq451:
    'If you are exercising your right to change your mind after purchasing any items you will need to cover the cost of shipping them back to us. ',
  faq452:
    'If your order arrived with damaged or defective parts and we are unable to provide you a replacement, as well as refunding the appropriate purchase cost we will assist you in returning them to us at our cost. ',

  faqmodel: 'BUILDING YOUR MODEL',
  faq460: 'Where are the instructions how to build? ',
  faq461:
    'You’ll find comprehensive videos on our website, as well as written instructions in PDF form. Instructions will be published online as they become required.',
  faq470: 'What if I don’t understand some part of the instructions and still need help?',
  faq471:
    'Just contact us on 2133 1661 or at helpdesk@stk-workshop.com and we’ll answer your questions about building.',
  faq480: 'Do I need any special tools to build?',
  faq481:
    'No. STK products are designed to be easy to build using items that are commonly owned or easily available. Some packs include special tools where required.',
  faq482: 'What is the appropriate age range?',
  faq483: 'Build The Millennium Falcon ',
  faq483a:
    'is not a toy. The kits are designed for adults aged 16 and older. The model includes detailed and delicate parts replicating the original movie prop. The specialist tools and glues supplied should be handled with some care. Assembled kits, including models assembled and painted by the STK Workshop Build Service, include fine, fragile details so we recommend that they be handled by responsible enthusiasts aged 13 and older.',
  faq484:
    'Ability and experience will differ from person to person and some younger modellers may be able to assemble the kits successfully. If you are concerned about the appropriateness for your younger modeller, please review the instruction video before making your purchase decision.',
  faq490: 'Do I need to paint my model? ',
  faq491:
    'The model parts are manufactured in the correct colours or pre-painted so you don’t need to paint your model if you don’t want to.',
  faq492:
    'However the original studio prop of the Millennium Falcon has ‘weathering’ effects that are only possible to replicate once the model is assembled. We will provide comprehensive instructions for those who would like to add the full weathering effects and other special hand-painting touches to their model.',
  faq493:
    'You can select one of the STK Workshop Build Service options to have one of our master modellers assemble and paint your model for you.',
  faq494: 'Is this DeAgostini’s product? What’s the difference between this and the De Agostini model?',
  faq495:
    'Yes, STK Workshop has the exclusive distribution licence from De Agostini to market and sell Build The Millennium Falcon in the countries where we offer it for sale. The model is the same as De Agostini’s previously published 100-issue part work in terms of its basic outer appearance. The version sold by STK Workshop has additional lighting effects in the cockpit and hold and the engine.',
  faq496:
    'Do you sell just the lighting parts? Can I use your product to modify De Agostini’s 100-issue part work version?',
  faq497:
    'To accommodate the extra circuitry and cabling for the new lighting, certain non-visible, internal parts of the model are different to the original De Agostini part work. The lighting components will not fit directly to your completed part work model, which itself is not designed to be disassembled. More experienced modelers may be able to modify the new lighting parts and/or their existing models to fit together but we cannot provide any warranty or support for customers who do undertake that task. All the lighting components for the cockpit and hold are contained in the Pack One kit and are not available for sale separately.',

  faqtroub: 'TROUBLESHOOTING',
  faq500: 'My delivery doesn’t look like the picture',
  faq501:
    'Images on the website are for illustration only. Product specifications are subject to minor change and variation from images on the website. ',
  faq502: 'Hand-painted parts may naturally vary slightly between different production pieces.',
  faq503:
    'Differences in customers’ device settings will mean that colours displayed may not match appearance of actual products. ',
  faq504:
    'However, you can be assured that all STK Workshop products are subject to the strictest standards of quality control and will in every case match the original versions approved by licensors.',
  faq510: 'I’m having trouble using the website',
  faq511: 'Please contact us on 2133 1661 or at helpdesk@stk-workshop.com',
  faq512:
    'Remember that although we can help you navigate the order and checkout process, our operators cannot place your order for you and you never need to share any of your payment/card information during a phone call or an email conversation.',
  faq520: 'I can’t find the answer to my question in these FAQ',
  faq521: 'Please contact us on 2133 1661 or at helpdesk@stk-workshop.com',

  faqmy: 'MY ACCOUNT',
  faq530: 'Do I need to create an account?',
  faq531:
    'You can complete your order on stk-workshop.com without creating an account. You’ll just need to provide an email address so that we can send you confirmation of your order and a tracking number for your delivery. But from there, you only need to decide a password and you’ve finished creating your account. ',
  faq540: 'What are the benefits of creating an account?',
  faq541:
    'STK Workshop account holders are our VIP customers. By creating an account you’ll receive news of special discounts, pre-order announcements, invitations to events, competitions, free gifts, product giveaways and more. Most offers are not advertised anywhere else and we’ll only email when there’s something worth bringing to the attention of our VIP customers.',
  faq542: ' ',
  faq550: 'How do I create an account?',
  faq551: 'Just follow the instructions after you place your order.',
  faq560: 'Can I create an account if I haven’t made a purchase?',
  faq561:
    ' Sorry - the My Account area is only for customers who have purchased products. But everyone can sign-up to our email newsletter and follow us on social media for the latest news.',
  faq570: 'Do I have to subscribe to your mailing list?',
  faq571:
    'It’s not necessary to subscribe to our mailing list. But please note that as many offers, invitations and giveaways are limited in number, they are advertised and allocated on a first come, first served basis. Customer mailing list readers will usually be the among the first to snap them up first so we do suggest you stay signed up. And if you really wish to stop receiving them it’s easy to unsubscribe any time.',
  faq572:
    'But please note that as many offers, invitations and giveaways are limited in number, they are advertised and allocated on a first come, first served basis. Mailing list readers will usually be the among the first to snap them up first so we suggest you stay signed up. ',
  faq573:
    'We’re anti-spam just like you so you can be assured we’ll keep our emails short, irregular and relevant. And if you really wish to stop receiving them it’s easy to unsubscribe any time. ',
  faq580: 'How do I unsubscribe from your mailing list?',
  faq581: 'Just click the unsubscribe link in the email.',

  faqcustomer: 'CUSTOMER SERVICE',
  faq590: 'How can I contact you?',
  faq591:
    'By phone on 2133 1661 during the hours of 09:00 to 18:00 Monday to Friday (except public holidays) and by email anytime at helpdesk@stk-workshop.com.',
  faq592: 'Or you can send us a message on Facebook or WhatsApp.',

  privacy00:
    "STK Workshop (collectively “we” or the “Company” ) respect the privacy rights of visitors to the Company’s website and mobile apps and of individuals who participate in, access or sign up to any of the Company's services, activities or online content (collectively referred to as “Services”). We endeavor to ensure that all our collection, transmission, storage and usage of personal data shall be carried out in compliance with the Personal Data (Privacy) Ordinance, Cap 486 of the laws of the Hong Kong Special Administrative Region (“PDPO”).",
  privacy01: 'The term “personal data” shall have the meaning ascribed to it by PDPO.',
  privacy10: '1.',
  privacy10a: 'PURPOSE OF COLLECTION AND USE OF PERSONAL DATA',
  privacy11:
    '1.1 You need not supply any personal data in order to access the Company’s website. When you participate in, access or sign up to any of the Services, personal data is collected from you to enable us to provide you with the Services. You may decline to provide us with the requested personal data, but in such case we may not be able to provide the Services to you. By submitting your personal data you consent to the use of that data as set out in this Privacy Policy Statement.',
  privacy12: '1.2 Personal data collected from you may be used by the Company for:',
  privacy121: '1.2.1 identifying you and any accounts you hold with us',
  privacy122: '1.2.2 enabling the provision of the Services to you',
  privacy123: '1.2.3 conducting identity verification and/or credit checks  ',
  privacy124:
    '1.2.4 processing of payment instructions or collection of amounts outstanding from you in relation to the provision of the Services',
  privacy125: '1.2.5 order processing, billing and fulfilment',
  privacy126: '1.2.6 direct marketing of the Services',
  privacy127: '1.2.7 direct marketing of the products and services of the Company’s business partners',
  privacy128: '1.2.8 designing services for you',
  privacy129: '1.2.9 conducting research, statistical analysis and behavioral analysis',
  privacy1210: '1.2.10 conducting program viewership survey and analysis',
  privacy1211: '1.2.11 customer profiling and analysing your purchasing preferences',
  privacy1212:
    '1.2.12 enabling you to participate in interactive features of our service, including identifying your friends or individuals, and sharing and communicating with them your shopping experience, when you choose to do so',
  privacy1213:
    '1.2.13 making suggestions and recommendations to you and other users of our Services or goods and services that may interest you or them',
  privacy1214: '1.2.14 customising the Company’s website and its content to your preferences',
  privacy1215: '1.2.15 provisioning of customer services ',
  privacy1216: '1.2.16 handling your complaints and account enquiries',
  privacy1217: '1.2.17 fraud prevention and detection',
  privacy1218: '1.2.18 auditing',
  privacy1219: '1.2.19 making such disclosures as required by applicable laws, rules and regulations',
  privacy1220:
    '1.2.20 any other purposes directly related to the purpose for which the personal data were originally collected',
  privacy20: '2.',
  privacy20a: 'TYPES OF PERSONAL DATA COLLECTED',
  privacy21: '2.1  Types of personal data collected by the Company may include:',
  privacy211:
    '2.1.1 Your personal information and contact information such as your name, gender, date of birth, identity card number, telephone number, social media contact, email address, residential address, mailing address, and billing address;',
  privacy212: '2.1.2 Your business information such as company name and business title;',
  privacy213:
    '2.1.3 Your account details, including first and last 4 digits of credit card account numbers, or user accounts;',
  privacy214: '2.1.4 Your household income range, and personal interest;',
  privacy215:
    '2.1.5 IP address, real-time geographic location data, browser settings, browsing records, and/or other Internet log information of your computer or mobile device; and',
  privacy216:
    '2.1.6 Telephone numbers and email addresses contained in your phone book, when you choose to participate in interactive features of our service. In this case we will have informed you when you access to our service and by providing that data to us you agree that you have obtained consent from those individuals in the phone books.',
  privacy30: '3.',
  privacy30a: 'CONFIDENTIALITY, DISCLOSURE AND SECURITY OF PERSONAL DATA',
  privacy31:
    '3.1  The Company does not trade your personal information and contact information for gain without your written consent. All personal data collected and held by the Company will be kept confidential, but where disclosure is necessary for the Company to comply with any statutory obligations or requirements, or for the Company to provide the Services to you or to carry out the original purpose, or a directly related purpose, for which the personal data were collected, those data may be provided to the following parties (whether within or outside Hong Kong):',
  privacy311:
    '3.1.1 Competent court of law, law enforcement agencies, or other governmental or statutory authorities, institutions or organisations;',
  privacy312:
    '3.1.2 Company’s associated companies, contractors, agents, sellers or suppliers of the goods/services, or other service operators, who are involved in the sale and marketing, administration or provision of the Services;',
  privacy313:
    '3.1.3 Any other person under a duty of confidentiality to the Company including a member of its group of companies, IT consultants, data processors, auditors, accountants, or lawyers, which has undertaken to keep such information confidential; and',
  privacy314: '3.1.4 Banks, financial institutions, credit card issuing companies or debt collection agencies.',
  privacy32:
    '3.2  Any questions, comments, suggestions or information other than personal data sent or posted to our website, or any part of the site by you will be considered as voluntarily provided to the Company on a non-confidential and non-proprietary basis. We reserve the right to use, reproduce, disclose, transmit, publish and/or post elsewhere such information freely, including passing it to any associated company for example, in connection with the development and marketing of services and to meet user needs. ',
  privacy33:
    '3.3  All reasonable efforts are made to ensure that any personal data held by the Company is stored in a secure and safe place.',
  privacy34:
    '3.4  All personal data which we collect is kept confidential to the best of our ability. You will appreciate however, that we cannot guarantee the security of transmission. ',
  privacy40: '4.',
  privacy40a: 'TRANSFER OF PERSONAL DATA OUTSIDE HONG KONG',
  privacy41:
    '4.1  If necessary, the Company may transfer the personal data to places outside the Hong Kong Special Administrative Region for carrying out the purposes, or the directly related purposes, for which the personal data were collected. All the transfer of those personal data will be carried out in compliance with the requirements of the PDPO.',
  privacy50: '5.',
  privacy50a: 'RETENTION OF PERSONAL DATA',
  privacy51:
    '5.1  If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party. If your mobile number is used for account login, you shall ensure that timely update is made by logging in to your account at “Account Profile” when your mobile number is to be changed or returned to your mobile operator. This is important to ensure that your account information will not be accessed by a third party who may have acquired the right to use your discarded mobile number. You are solely responsible for securing your account in this particular way. We shall have no liability to you for any loss or damage in this regard.',
  privacy52:
    '5.2  Unless there is a mandatory legal requirement for us to keep your personal data for a specified period, we will only retain your personal data for as long as is necessary to fulfill the purpose for which the personal data were originally collected. ',
  privacy60: '6.',
  privacy60a: 'DIRECT MARKETING',
  privacy61:
    '6.1  The Company intends to use the personal data collected from you in direct marketing and the Company requires your consent for that purpose. Your name, telephone number, email address, contact address, social media contact, products and service portfolio information, transaction pattern and behavior, browsing records, content viewing habits, and personal interest held by the Company may be used by the Company in direct marketing of the Services and the following classes of products or services offered by the Company and its business partners.',
  privacy62:
    '6.2  We may contact you by mail, telephone, social media, SMS, text/picture/video message, fax, email. If you prefer not to receive any direct marketing communications from us, you can opt out at any time by updating your preferences through your registered account or a unsubscribe link provided. Upon receipt of your request, we shall cease to so use your personal data without charge to you.',
  privacy70: '7.',
  privacy70a: 'USE OF COOKIES',
  privacy71:
    '7.1  A cookie is a small text file which is placed onto your computer (or other electronic device) when you access the Company’s website. We use cookies on our website to:',
  privacy711: '7.1.1 recognise you whenever you visit this website',
  privacy712:
    '7.1.2 obtain information about your preferences, viewing and browsing behavior, online movements and use of the Internet',
  privacy713: '7.1.3 keep track of the items stored in your shopping basket and take you through the checkout process',
  privacy714:
    '7.1.4 carry out research and statistical analysis to help improve our Services and to help us better understand our visitor and customer requirements and interests',
  privacy715:
    '7.1.5 target our marketing and advertising campaigns and those of our business partners and advertisers more effectively by providing interest-based advertisements that are personalised to your interests',
  privacy716: '7.1.6 make your online experience more efficient and enjoyable',
  privacy717: '7.1.7 enable tighter security',
  privacy72:
    '7.2  The information we obtain from our use of cookies may not contain your personal data. Although we may obtain information about your computer or other electronic device such as IP address, browser settings, browsing records, and/or other Internet log information, this may not be able to identity you personally. To the extent that non-personal data is combined with personal data, we treat the combined data as personal data for the purposes of this Privacy Policy Statement. In certain circumstances we may collect personal data about you, but only where you voluntarily provide it by completing an online form, or where you purchase goods from our website or use the Services.',
  privacy73:
    '7.3  If you want to disallow the use of cookies, you can do so on your own web browser. If you disable cookies, you acknowledge that you may not be able to use some of the functionality of our website.',
  privacy80: '8.',
  privacy80a: 'LINKS',
  privacy81:
    '8.1  This Privacy Policy Statement only applies to the Company’s website. Our website may contain links to other sites and pages. By activating a link, such as for example by clicking on the banner of an advertiser, you leave our website and the Company does not exercise control over any personal data or any other information you give to any other entity after you have left our website. Access to and use of such other websites is at your own risk.',
  privacy90: '9.',
  privacy90a: 'DATA ACCESS AND CORRECTION',
  privacy91:
    '9.1  If you have any query in relation to the Company’s privacy policy or you wish to access or correct your personal data held by us, you may contact us by writing to the following address:',
  privacy91a: 'STK Workshop Limited',
  privacy91b: 'Flat A, 3/F Wah Shing Industrial Building',
  privacy91c: '18 Cheung Shun Street',
  privacy91d: 'Lai Chi Kok',
  privacy91e: 'Kowloon',
  privacy91f: 'Hong Hong',
  privacy91g: 'For the attention of: Personal Data Officer',
  privacy92:
    '9.2  The Company reserves the right to charge you a reasonable fee for complying with a data access request as permitted by the PDPO.',
  privacy100: '10.',
  privacy100a: 'Authorized version of this Notice',
  privacy101:
    '10.1  This Notice is written in the English Language and may be translated into other languages. In the event of inconsistency between the English version and the translated version of this Notice, the English version shall prevail.',
  privacyDate: '2019',

  webuse000:
    'Welcome to the STK Workshop (“STK Workshop” or “Our” or “We” or “Us”) website and thank you for visiting.',
  webuse001:
    'The terms of use (“these Terms”) appearing in this Notice constitute a legal contract between you and STK Workshop (collectively, “Everyone”) and govern your use of this website, as well as other websites that STK Workshop may control. These Terms also apply to the use of all text, data, information, software, graphics, photographs, all other audio and visual materials (all of which We refer to as “Materials”) that STK Workshop and its subsidiaries may make available to you, as well as all services STK Workshop may provide through any of our websites (all of which are referred to in these Terms as “this Website”).',
  webuse002:
    'PLEASE READ THESE TERMS CAREFULLY BEFORE BROWSING THIS WEBSITE. USING THIS WEBSITE INDICATES THAT YOU HAVE BOTH READ AND ACCEPT THESE TERMS. YOU SHALL NOT USE THIS WEBSITE IF YOU DO NOT ACCEPT THESE TERMS.',
  webuse010: '1.',
  webuse010a: 'Accessing This Website',
  webuse011:
    '1.1  When you access, browse and use this Website, it is your duty and responsibility to comply with these Terms whether through any account that you may setup through or on this Website. Some of the Materials will only be available to you if you have an account. You agree to provide true, accurate, current, and complete information for so long as you use this Website. Because it is your account, it is your responsibility to obtain and maintain all equipment, services and software needed for access to and use of this Website as well as paying related charges for operating your account. It is also your responsibility to maintain the confidentiality of all related password(s). Should you believe your password or other security information for this Website has been breached in any way, you must immediately notify STK Workshop.',
  webuse012:
    '1.2  We also collect certain personal information about you solely in connection with your access and use of this Website. STK Workshop’s use of those information is governed by the provisions of the Privacy Statement of STK Workshop.',
  webuse013:
    '1.3  We reserve the right to require you to change your password or restrict your access to this Website as we deem reasonably necessary.',
  webuse020: '2.',
  webuse020a: 'Permitted Use of this Website',
  webuse021:
    '2.1  You shall use this Website for your personal purposes where you are purchasing products and services of STK Workshop for your personal use which shall exclude use for business and commercial purposes (“Permitted Uses”).',
  webuse022:
    '2.2  We hereby grant you a limited, personal, non-exclusive and non-transferable license to use and to display the Materials. Your right to use the Materials is conditioned on your compliance with these Terms. You have no other rights in this Website or any Materials and you may not modify, edit, copy, reproduce, create derivative works of, reverse engineer, alter, enhance or in any way exploit any of this Website or Materials in any other manner.',
  webuse023:
    '2.3  If you make copies of any of this Website while engaging in Permitted Uses, we require that you shall keep and comply with all of STK Workshop’s intellectual property rights and other proprietary notices as they appear on this Website.',
  webuse030: '3.',
  webuse030a: 'Unauthorized Use of this Website and acceptable use policy',
  webuse031:
    '3.1  You are hereby notified that all rights in this Website remain the property of STK Workshop. Your use of this Website shall be limited in the manner as provided for in this Notice. Any other use of this Website beyond the Permitted Uses is prohibited and, therefore, constitutes unauthorized use of this Website.',
  webuse032:
    '3.2  Unauthorized use of this Website may result in violation of Hong Kong and international laws. You must therefore follow STK Workshop’s Acceptable Use Policy whenever you use this Website or any services offered by STK Workshop through this Website.',
  webuse040: '4.',
  webuse040a: 'Terminating the use of this Website',
  webuse041:
    '4.1  We may terminate your use of this Website at any time at our sole discretion and our decision shall be final and subject to no appeal. Your use of this Website will automatically terminate in the event you breach any of these Terms. That is to say, unless we otherwise agree with you in writing in advance, we may terminate, suspend, or modify your registration with, or access to, all or part of this Website, without notice, at any time and for any reason.',
  webuse042: '4.2  You may also discontinue your access to and use of this Website at any time.',
  webuse043:
    '4.3  In the event of an automatic termination for breach of any of these Terms, you must immediately destroy any downloaded or printed materials as well as any copies thereof.',
  webuse050: '5.',
  webuse050a: 'Disclaimers',
  webuse051:
    '5.1  This Website is provided on a “as is” and “with all faults” basis and the entire risk as to the quality and performance of this Website is with you.',
  webuse052:
    '5.2  STK Workshop expressly disclaims all warranties of any kind (express, implied or statutory) with respect to this Website, which includes but not limited to any implied or statutory warranties of merchantability, fitness for a particular use or purpose, title and non-infringement of intellectual property rights.',
  webuse053:
    '5.3  For the purpose of this Disclaimers, STK Workshop does not promise you that this Website is free of problems. Without limiting the generality of the foregoing, STK Workshop makes no warranty that this Website will meet your requirements or that this Website will be uninterrupted, timely, secure, or error free or that defects in this Website will be corrected. STK Workshop makes no warranty as to the results that may be obtained from the use of this Website or as to the accuracy or reliability of any information obtained through this Website. No advice or information, whether oral or written, obtained by you through this Website or from STK Workshop, its subsidiaries, or other affiliated companies, or its or their suppliers (or the respective officers, directors, employees, servant or agents of any such entities) (collectively, “the STK Workshop Parties”) shall create any warranty. STK Workshop disclaims all equitable indemnities.',
  webuse060: '6.',
  webuse060a: 'Limitation of Liability',
  webuse061:
    '6.1  In no event will any of the STK Workshop Parties be liable for (A) any indirect, special, consequential, punitive or exemplary damages or (B) damages which include, without limitation, those resulting from loss of revenues, lost profits, loss of goodwill, loss of use, business interruption or other intangible losses, and those arising out of or in connection with this Website (including but not limitation to use, inability to use or the results of use of this Website), whether such damages are based on warranty, contract, tort, statute or otherwise and even if any of the STK Workshop Parties has been advised (or should have known) of the possibility of such damages.',
  webuse070: '7.',
  webuse070a: 'Exclusions and Limitations',
  webuse071:
    "7.1  While it is understood that the laws of Hong Kong do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain damages, to that extent, the STK Workshop Parties may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities. The scope and duration of such warranty and the amount of the STK Workshop Party's liability shall be limited to the minimum amount permitted under such applicable law.",
  webuse080: '8.',
  webuse080a: 'Other Agreements, Software, Services or Access',
  webuse081:
    "8.1  STK Workshop may provide products (such as hardware or software), services (such as software subscription services, hardware maintenance or repair or software maintenance, installation, or training) or access via this Website under the terms of a separate agreement between you and STK Workshop, such as a license agreement or separate terms of sale and warranty terms (each to be defined as “Other Agreement”). STK Workshop's obligations regarding any product, service, or access that it makes available to you under any Other Agreement shall be governed solely by the Other Agreement under which such product, service or access is provided and these Terms shall not be deemed or construed to alter the terms of any such Other Agreement. If there is an inconsistency between These Terms and any Other Agreement, the Other Agreement shall prevail.",
  webuse082:
    '8.2  For the purpose of illustration only: the use of any software that is made available to download from the this Websites (“Software”) shall be governed by the terms of the end user license agreement, if any, that accompanies or is included with the Software (“License Agreement”). You may not install any Software that is accompanied by or includes a License Agreement unless you first have agreed to the License Agreement. If there is no License Agreement, These Terms shall govern your use of that Software.',
  webuse090: '9.',
  webuse090a: 'Right to modify this Website',
  webuse091:
    '9.1  STK Workshop reserves the right to modify, suspend, or discontinue this Website at any time without notice to you. We would like to draw your attention to the following:',
  webuse0911:
    '9.1.1 STK Workshop may make changes to the this Website, or to the products, services and prices described in this Website, at any time without notice.',
  webuse0912:
    '9.1.2 This Website may be out of date and STK Workshop makes no commitment whatsoever to update this Website.',
  webuse0913:
    '9.1.3 Information published on this Website may refer to products, programs, or services that are not available in your country.',
  webuse100: '10.',
  webuse100a: 'Submission(s) to this Website',
  webuse101:
    '10.1 Certain areas of this Website (e.g., chat rooms, customer ratings, review areas, community and support forums) may permit you to submit feedback, information, data, text, software, music, sound, photographs, graphics, video, messages, or other materials (each to be defined as “User Submission”). By submitting User Submission, you agree that you are solely responsible for all of your User Submissions and that any such User Submission is considered both non-confidential and non-proprietary. Further, STK Workshop does not guarantee that you will have any recourse through STK Workshop, the STK Workshop Parties or any third party to edit or delete any User Submission you have submitted.',
  webuse102: '10.2 By submitting any User Submission, You represent and warrant that',
  webuse1021:
    '10.2.1  You are at least 13 years old and have obtained permission from your parent(s) or lawful guardian(s) in using this Website;',
  webuse1022:
    '10.2.2  You own all rights in your User Submissions (including, without limitation, all rights to the audio, video, or digital recording and the performance contained in your User Submissions) or, alternatively, you have acquired all necessary rights in your User Submissions to enable you to grant to STK Workshop the rights in your User Submissions as described in these Terms;',
  webuse1023:
    '10.2.3  You have paid and will pay in full all license fees, clearance fees, and other financial obligations, of any kind, arising from any use or commercial exploitation of your User Submissions;',
  webuse1024:
    '10.2.4  You are the individual pictured and/or heard in your User Submissions or, alternatively, you have obtained permission from each person (including consent from parents or guardians for any individual under the age of eighteen (18)) who appears and/or is heard in your User Submissions to grant the rights to STK Workshop as described in these Terms;',
  webuse1025:
    '10.2.5  Your User Submissions do not infringe the copyright, trademark, patent, trade secret, or other intellectual property rights, privacy rights, or any other legal or moral rights of any third party;',
  webuse1026: '10.2.6  You voluntarily agree to waive all “moral rights” that you may have in your User Submission;',
  webuse1027:
    '10.2.7  Any information contained in your User Submission is not known by you to be false, inaccurate, or misleading;',
  webuse1028:
    '10.2.8  Your User Submission does not violate any law of Hong Kong and the place which you reside (including, but not limited to, those governing export control, consumer protection, unfair competition, anti-discrimination, or false advertising);',
  webuse1029:
    "10.2.9  Your User Submission is not, and may not reasonably be considered to be, defamatory, libelous, hateful, racially, ethnically, religiously, or otherwise biased or offensive, unlawfully threatening, or unlawfully harassing to any individual, partnership, or corporation, vulgar, pornographic, obscene, or invasive of another's privacy;",
  webuse10210:
    '10.2.10 You were not and will not be compensated or granted any consideration by any third party for submitting your User Submission;',
  webuse10211:
    '10.2.11 Your User Submission does not incorporate materials from a third party web site, or addresses, email addresses, contact information, or phone numbers (other than your own);',
  webuse10212:
    '10.2.12 Your User Submission does not contain any viruses, worms, spyware, adware, or other potentially damaging programs or files;',
  webuse10213:
    '10.2.13 Your User Submission does not contain any information that you consider confidential, proprietary, or personal; and',
  webuse10214:
    '10.2.14 Your User Submission does not contain or constitute any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation.',
  webuse103:
    '10.3 By submitting a User Submission, you grant to STK Workshop and the STK Workshop Parties an irrevocable, perpetual, transferable, non-exclusive, fully-paid, worldwide, royalty-free license (sublicensable through multiple tiers) to:',
  webuse1031:
    '10.3.1  Use, distribute, reproduce, modify, adapt, publish, translate, publicly perform, and publicly display your User Submissions (or any modification thereto), in whole or in part, in any format or medium now known or later developed;',
  webuse1032:
    '10.3.2  Use, (and permit others to use) your User Submission in any manner and for any purpose (including, without limitation, commercial purposes) that STK Workshop deems appropriate in its sole discretion (including, without limitation, to incorporate your User Submission or any modification thereto, in whole or in part, into any technology, product, or service);',
  webuse1033:
    '10.3.3  Display advertisements in connection with your User Submissions and to use your User Submissions for advertising and promotional purposes.',
  webuse1034:
    '10.3.4  STK Workshop may, but is not obligated to, pre-screen User Submissions or monitor any area of this Website through which User Submissions may be submitted. STK Workshop is not required to host, display, or distribute any User Submissions on or through this Website and may remove at any time or refuse any User Submissions for any reason whose decision shall be final and subject to no appeal. STK Workshop is not responsible for any loss, theft, or damage of any kind to any User Submissions. Further, you agree that STK Workshop may freely disclose your User Submission to any third party absent any obligation of confidence on the part of the recipient',
  webuse110: '11.',
  webuse110a: 'Intellectual Property Rights',
  webuse111:
    '11.1 STK Workshop respects the intellectual property rights of others and you are requested to do the same. STK Workshop may, in appropriate circumstances and at our sole discretion and without prior notice, terminate service and/or access to this Website for users who are found to have infringed the intellectual property rights of others.',
  webuse120: '12.',
  webuse120a: 'Links to Third Party Websites',
  webuse121:
    '12.1 For your convenience, STK Workshop may provide links on this Website to third-party websites. If you use these links, you will be directed to leave this Website. While you are using the third party websites, STK Workshop is not obligated to review any of those websites that you link to from this Website. We do not control any of the third party websites, and is not responsible for any of those websites or the products, services, or content available through any of them. That is to say, STK Workshop does not endorse or make any representations about such third party websites, any information, software, products, services, or materials found there or any results that may be obtained from using them. If you decide to access any of the third party websites linked to from this Website, you do so entirely at your own risk and you shall be deemed subject to the privacy policies and terms and conditions of use of those third party websites. These Terms are not applicable to your use of any such third party websites.',
  webuse130: '13.',
  webuse130a: 'Linking to this Website',
  webuse131:
    '13.1 For your convenience, we allow you to create links to this Website from other websites. Unless an express agreement states otherwise, you must comply with the following terms and all applicable laws if you provide anyone with a link to this Website:',
  webuse1311: '13.1.1  You may link to, but shall not copy, any Materials;',
  webuse1312:
    '13.1.2  You shall only use and reproduce the STK Workshop logo or trademark if you follow our trademark and logo usage policy found here.',
  webuse1313: '13.1.3  You shall not create a browser or border environment around any Materials;',
  webuse1314:
    '13.1.4  You shall not imply that STK Workshop endorses or is affiliated with any such website that links to this Website or any products, services, or content available through that website;',
  webuse1315: '13.1.5  You shall not misrepresent your relationship with STK Workshop;',
  webuse1316:
    '13.1.6  You shall not present false or misleading information about STK Workshop, its products, or its services;',
  webuse1317:
    '13.1.7  The website that links to this Website shall not contain content that could be construed as distasteful, offensive, or controversial; and',
  webuse1318:
    '13.1.8  The website that links to this Website shall contain only content that is appropriate for all age groups.',
  webuse140: '14.',
  webuse140a: 'Trade marks',
  webuse141:
    '14.1 STK Workshop has rights in the registered trademarks, STK Workshop and the STK Workshop Logo (shown below), as well as other registered and unregistered trademarks',
  webuse142:
    '14.2 You may use STK Workshop trademarks, in text, to refer fairly and accurately to STK Workshop, its products and its services, subject to written agreement having entered into with STK Workshop prior to use thereof.',
  webuse143:
    '14.3 The STK Workshop Logo is reserved for use by STK Workshop and those STK Workshop partners and licensees that have a written agreement with STK Workshop that specifically authorizes logo use.  No other use of STK Workshop logos is permitted.',
  webuse144:
    "14.4 You may not use STK Workshop trademarks in a manner likely to mislead consumers as to your relationship with STK Workshop, as to STK Workshop's sponsorship or endorsement of you, your company, products or services, or as to the origin of your products or services.  Any use of STK Workshop trademarks that is likely to confuse or mislead the public is unlawful and expressly prohibited.",
  webuse150: '15.',
  webuse150a: 'International Issues',
  webuse151:
    '15.1 STK Workshop administers this Website from its offices in Hong Kong. STK Workshop makes no representation that this Website is appropriate or available for use outside Hong Kong and access to this Website from territories where its contents are illegal or restricted is prohibited.',
  webuse152:
    '15.2 Access of this Website from outside Hong Kong is done at your own initiation and you are responsible for compliance with applicable Laws.',
  webuse153:
    '15.3 You agree to comply with all export and re-export restrictions and regulations of Hong Kong or other foreign agencies and authorities in connection with your use of this Website and to not, in violation of any Laws, transfer, or authorize the transfer, of any Materials to a prohibited country or otherwise in violation of any Laws. In particular, but without limitation, the Materials may not, in violation of any Laws, be exported or re-exported:',
  webuse154:
    '15.4 By using any Materials subject to any such restrictions and regulations, you represent and warrant that you are not located in, under the control of, or a national or resident of any such country where import, export and re-export restrictions and regulations thereof apply.',
  webuse160: '16.',
  webuse160a: 'Indemnification',
  webuse161:
    '16.1 You agree to indemnify, defend, and hold harmless the STK Workshop Parties from and against any and all claims, liabilities, damages, losses, costs, expenses, or costs (including reasonable legal costs) that any such parties may incur as a result of or arising from your (or anyone using your account’s) violation of these Terms. STK Workshop reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you and, in such case, you agree to cooperate fully and without reservation with STK Workshop in the conduction of defense of such claim.',
  webuse170: '17.',
  webuse170a: 'Communications',
  webuse171:
    '17.1 When you visit this Website or send e-mails or electronic messages via any electronic media to STK Workshop, you are communicating with STK Workshop electronically. We may respond to you by e-mail or by posting notices on this Website or by other electronic means. You agree that all such notices, disclosures, and other communications that STK Workshop provides to you electronically satisfy any legal requirement that such communications be deemed to be in writing.',
  webuse180: '18.',
  webuse180a: 'Ownership',
  webuse181:
    '18.1 Unless otherwise specified in these Terms, all Materials, as well as the arrangement of them on this Website are the sole property of STK Workshop. All rights not expressly granted herein are reserved. Except as otherwise required or limited by applicable law of Hong Kong, any reproduction, distribution, modification, retransmission, or publication of any copyrighted material is strictly prohibited without the express written consent of the copyright owner or license.',
  webuse190: '19.',
  webuse190a: 'Entire Agreement',
  webuse191:
    '19.1 These Terms, together with any additional terms to which you agree when using any part of this Website, constitute the entire and exclusive and final statement of the agreement as between everyone using this Website for all purposes. Such agreement supersedes all prior agreements or negotiations or representations between you and STK Workshop with respect to the same. The STK Workshop Parties are third party beneficiaries with respect to the provisions in these Terms that reference them.',
  webuse200: '20.',
  webuse200a: 'Governing Law and Dispute Resolution',
  webuse201:
    '20.1 These Terms shall be governed by the laws of Hong Kong notwithstanding that your actual place of residence or business is located outside Hong Kong. All legal actions arising from or relating to these Terms or your use of this Website shall be instituted in the competent courts of Hong Kong and you hereby irrevocably submit to the exclusive personal jurisdiction of such courts for such purpose.',
  webuse210: '21',
  webuse210a: 'Miscellaneous',
  webuse211:
    '21.1 The failure of STK Workshop to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, you nevertheless agree that that the other provisions of these Terms shall remain in full force and effect and be binding on you. The section titles in these Terms are for convenience only and have no legal or contractual effect. These Terms shall remain in full force and effect notwithstanding any termination of your use of this Website. For the avoidance of doubt, any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by STK Workshop without restriction. ',
  webuse220: '22.',
  webuse220a: 'Right to modify these Terms',
  webuse221:
    '22.1 STK Workshop may, in its sole and absolute discretion, change these Terms (including any other documents that are referenced in or linked to from these Terms) from time to time. STK Workshop may post notice of such changes on this Website as applicable. However, STK Workshop is not obliged to post the relevant notice. It remains your responsibility to browse the relevant page of this Website to check for updated Notice of Use of this Website. If you object to any such changes, you shall cease using this Website. Continued use of this Website following notice of any such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes. Certain provisions of these Terms may be superseded by expressly-designated legal notices or terms located on particular pages of this Website and, in such circumstances, the expressly-designated legal notice or term shall be deemed to be incorporated into These Terms and to supersede the provision(s) of these Terms that are designated as being superseded.',
  webuse230: '23.',
  webuse230a: 'Authorized version of this Notice',
  webuse231:
    '23.1 This Notice is written in the English Language and may be translated into other languages. In the event of inconsistency between the English version and the translated version of this Notice, the English version shall prevail. ',
  webuseDate: 'May 6, 2019',

  'shipmentstatus.status': 'Shipment Status',
  'shipmentstatus.preparingshipment': 'Preparing Shipment',
  'shipmentstatus.shipped': 'Shipped',
  'shipmentstatus.shipmentfailed': 'Shipment Failed',
  cartBenefit1: 'SAVE 5%',
  cartBenefit2: 'FREE Delivery',
  cartBenefit3: 'Cancel anytime',
  cartBenefit4: 'Receive your issues before they are in stores*',
  cartBenefit5: 'Never miss a copy',
  cartBenefit6: 'Exclusive FREE GIFT of a Snoopy plush*',
  cartBenefitDisclaimer: '* Please refer to FAQ for details.',

  orderStatusCreated: 'Created',
  orderStatusConfirmedOrder: 'Confirmed Order',
  orderStatusPartiallyShipped: 'Partially Shipped',
  orderStatusFulfilled: 'Fulfilled',
  orderStatusPaymentFailed: 'Payment Failed',
  orderStatusCancelled: 'Cancelled',
  orderStatusRefunded: 'Refunded',

  twCity: 'city/county',
  twDistrict: 'district',
  postal_code: 'Postal Code',

  'twCity.taipeiCity': 'Taipei City',
  'twCity.keelungCity': 'Keelung City',
  'twCity.newTaipeiCity': 'New Taipei City',
  'twCity.yilanCounty': 'Yilan County',
  'twCity.hsinchuCity': 'Hsinchu City',
  'twCity.hsinchuCounty': 'Hsinchu County',
  'twCity.taoyuanCity': 'Taoyuan City',
  'twCity.miaoliCounty': 'Miaoli County',
  'twCity.taichungCity': 'Taichung City',
  'twCity.changhuaCounty': 'Changhua County',
  'twCity.nantouCounty': 'Nantou County',
  'twCity.chiayiCity': 'Chiayi City',
  'twCity.chiayiCounty': 'Chiayi County',
  'twCity.yunlinCounty': 'Yunlin County',
  'twCity.tainanCity': 'Tainan City',
  'twCity.kaohsiungCity': 'Kaohsiung City',
  'twCity.southChinaSeaIslands': 'South China Sea Islands',
  'twCity.penghuCounty': 'Penghu County',
  'twCity.pingtungCounty': 'Pingtung County',
  'twCity.taitungCounty': 'Taitung County',
  'twCity.hualienCounty': 'Hualien County',
  'twCity.kinmenIslands': 'Kinmen Islands',
  'twCity.lienchiangCounty': 'Lienchiang County',

  'twDistrict.zhongzhengDistrict': 'Zhongzheng District',
  'twDistrict.datongDistrict': 'Datong District',
  'twDistrict.zhongshanDistrict': 'Zhongshan District',
  'twDistrict.songshanDistrict': 'Songshan District',
  'twDistrict.daAnDistrict': 'Da’an District',
  'twDistrict.wanhuaDistrict': 'Wanhua District',
  'twDistrict.xinyiDistrict': 'Xinyi District',
  'twDistrict.shilinDistrict': 'Shilin District',
  'twDistrict.beitouDistrict': 'Beitou District',
  'twDistrict.neihuDistrict': 'Neihu District',
  'twDistrict.nangangDistrict': 'Nangang District',
  'twDistrict.wenshanDistrict': 'Wenshan District',
  'twDistrict.renAiDistrict': 'Ren’ai District',
  'twDistrict.xinyiDistrict': 'Xinyi District',
  'twDistrict.zhongzhengDistrict': 'Zhongzheng District',
  'twDistrict.zhongshanDistrict': 'Zhongshan District',
  'twDistrict.anleDistrict': 'Anle District',
  'twDistrict.nuannuanDistrict': 'Nuannuan District',
  'twDistrict.qiduDistrict': 'Qidu District',
  'twDistrict.wanliDistrict': 'Wanli District',
  'twDistrict.jinshanDistrict': 'Jinshan District',
  'twDistrict.banqiaoDistrict': 'Banqiao District',
  'twDistrict.xizhiDistrict': 'Xizhi District',
  'twDistrict.shenkengDistrict': 'Shenkeng District',
  'twDistrict.shidingDistrict': 'Shiding District',
  'twDistrict.ruifangDistrict': 'Ruifang District',
  'twDistrict.pingxiDistrict': 'Pingxi District',
  'twDistrict.shuangxiDistrict': 'Shuangxi District',
  'twDistrict.gongliaoDistrict': 'Gongliao District',
  'twDistrict.xindianDistrict': 'Xindian District',
  'twDistrict.pinglinDistrict': 'Pinglin District',
  'twDistrict.wulaiDistrict': 'Wulai District',
  'twDistrict.yongheDistrict': 'Yonghe District',
  'twDistrict.zhongheDistrict': 'Zhonghe District',
  'twDistrict.tuchengDistrict': 'Tucheng District',
  'twDistrict.sanxiaDistrict': 'Sanxia District',
  'twDistrict.shulinDistrict': 'Shulin District',
  'twDistrict.yinggeDistrict': 'Yingge District',
  'twDistrict.sanchongDistrict': 'Sanchong District',
  'twDistrict.xinzhuangDistrict': 'Xinzhuang District',
  'twDistrict.taishanDistrict': 'Taishan District',
  'twDistrict.linkouDistrict': 'Linkou District',
  'twDistrict.luzhouDistrict': 'Luzhou District',
  'twDistrict.wuguDistrict': 'Wugu District',
  'twDistrict.baliDistrict': 'Bali District',
  'twDistrict.tamsuiDistrict': 'Tamsui District',
  'twDistrict.sanzhiDistrict': 'Sanzhi District',
  'twDistrict.shimenDistrict': 'Shimen District',
  'twDistrict.yilan': 'Yilan',
  'twDistrict.toucheng': 'Toucheng',
  'twDistrict.jiaoxi': 'Jiaoxi',
  'twDistrict.zhuangwei': 'Zhuangwei',
  'twDistrict.yuanshan': 'Yuanshan',
  'twDistrict.luodong': 'Luodong',
  'twDistrict.sanxing': 'Sanxing',
  'twDistrict.datong': 'Datong',
  'twDistrict.wujie': 'Wujie',
  'twDistrict.dongshan': 'Dongshan',
  'twDistrict.suAo': "Su'ao",
  'twDistrict.nanAo': "Nan'ao",
  'twDistrict.diaoyutaiIslands': 'Diaoyutai Islands',
  'twDistrict.eastDistrict': 'East District',
  'twDistrict.northDistrict': 'North District',
  'twDistrict.xiangshanDistrict': 'Xiangshan District',
  'twDistrict.zhubei': 'Zhubei',
  'twDistrict.hukou': 'Hukou',
  'twDistrict.xinfeng': 'Xinfeng',
  'twDistrict.xinpu': 'Xinpu',
  'twDistrict.guanxi': 'Guanxi',
  'twDistrict.qionglin': 'Qionglin',
  'twDistrict.baoshan': 'Baoshan',
  'twDistrict.zhudong': 'Zhudong',
  'twDistrict.wufeng': 'Wufeng',
  'twDistrict.hengshan': 'Hengshan',
  'twDistrict.jianshi': 'Jianshi',
  'twDistrict.beipu': 'Beipu',
  'twDistrict.emei': 'Emei',
  'twDistrict.zhongliDistrict': 'Zhongli District',
  'twDistrict.pingzhenDistrict': 'Pingzhen District',
  'twDistrict.longtanDistrict': 'Longtan District',
  'twDistrict.yangmeiDistrict': 'Yangmei District',
  'twDistrict.xinwuDistrict': 'Xinwu District',
  'twDistrict.guanyinDistrict': 'Guanyin District',
  'twDistrict.taoyuanDistrict': 'Taoyuan District',
  'twDistrict.guishanDistrict': 'Guishan District',
  'twDistrict.badeDistrict': 'Bade District',
  'twDistrict.daxiDistrict': 'Daxi District',
  'twDistrict.fuxingDistrict': 'Fuxing District',
  'twDistrict.dayuanDistrict': 'Dayuan District',
  'twDistrict.luzhuDistrict': 'Luzhu District',
  'twDistrict.zhunan': 'Zhunan',
  'twDistrict.toufen': 'Toufen',
  'twDistrict.sanwan': 'Sanwan',
  'twDistrict.nanzhuang': 'Nanzhuang',
  'twDistrict.shitan': 'Shitan',
  'twDistrict.houlong': 'Houlong',
  'twDistrict.tongxiao': 'Tongxiao',
  'twDistrict.yuanli': 'Yuanli',
  'twDistrict.miaoli': 'Miaoli',
  'twDistrict.zaoqiao': 'Zaoqiao',
  'twDistrict.touwu': 'Touwu',
  'twDistrict.gongguan': 'Gongguan',
  'twDistrict.dahu': 'Dahu',
  'twDistrict.taiAn': "Tai'an",
  'twDistrict.tongluo': 'Tongluo',
  'twDistrict.sanyi': 'Sanyi',
  'twDistrict.xihu': 'Xihu',
  'twDistrict.zhuolan': 'Zhuolan',
  'twDistrict.centralDistrict': 'Central District',
  'twDistrict.eastDistrict': 'East District',
  'twDistrict.southDistrict': 'South District',
  'twDistrict.westDistrict': 'West District',
  'twDistrict.northDistrict': 'North District',
  'twDistrict.beitunDistrict': 'Beitun District',
  'twDistrict.xitunDistrict': 'Xitun District',
  'twDistrict.nantunDistrict': 'Nantun District',
  'twDistrict.taipingDistrict': 'Taiping District',
  'twDistrict.daliDistrict': 'Dali District',
  'twDistrict.wufengDistrict': 'Wufeng District',
  'twDistrict.wuriDistrict': 'Wuri District',
  'twDistrict.fengyuanDistrict': 'Fengyuan District',
  'twDistrict.houliDistrict': 'Houli District',
  'twDistrict.shigangDistrict': 'Shigang District',
  'twDistrict.dongshiDistrict': 'Dongshi District',
  'twDistrict.hepingDistrict': 'Heping District',
  'twDistrict.xinsheDistrict': 'Xinshe District',
  'twDistrict.tanziDistrict': 'Tanzi District',
  'twDistrict.dayaDistrict': 'Daya District',
  'twDistrict.shengangDistrict': 'Shengang District',
  'twDistrict.daduDistrict': 'Dadu District',
  'twDistrict.shaluDistrict': 'Shalu District',
  'twDistrict.longjingDistrict': 'Longjing District',
  'twDistrict.wuqiDistrict': 'Wuqi District',
  'twDistrict.qingshuiDistrict': 'Qingshui District',
  'twDistrict.dajiaDistrict': 'Dajia District',
  'twDistrict.waipuDistrict': 'Waipu District',
  'twDistrict.daAnDistrict': "Da'an District",
  'twDistrict.changhua': 'Changhua',
  'twDistrict.fenyuan': 'Fenyuan',
  'twDistrict.huatan': 'Huatan',
  'twDistrict.xiushui': 'Xiushui',
  'twDistrict.lukang': 'Lukang',
  'twDistrict.fuxing': 'Fuxing',
  'twDistrict.xianxi': 'Xianxi',
  'twDistrict.hemei': 'Hemei',
  'twDistrict.shengang': 'Shengang',
  'twDistrict.yuanlin': 'Yuanlin',
  'twDistrict.shetou': 'Shetou',
  'twDistrict.yongjing': 'Yongjing',
  'twDistrict.puxin': 'Puxin',
  'twDistrict.xihu': 'Xihu',
  'twDistrict.dacun': 'Dacun',
  'twDistrict.puyan': 'Puyan',
  'twDistrict.tianzhong': 'Tianzhong',
  'twDistrict.beidou': 'Beidou',
  'twDistrict.tianwei': 'Tianwei',
  'twDistrict.pitou': 'Pitou',
  'twDistrict.xizhou': 'Xizhou',
  'twDistrict.zhutang': 'Zhutang',
  'twDistrict.erlin': 'Erlin',
  'twDistrict.dacheng': 'Dacheng',
  'twDistrict.fangyuan': 'Fangyuan',
  'twDistrict.ershui': 'Ershui',
  'twDistrict.nantou': 'Nantou',
  'twDistrict.zhongliao': 'Zhongliao',
  'twDistrict.caotun': 'Caotun',
  'twDistrict.guoxing': 'Guoxing',
  'twDistrict.puli': 'Puli',
  'twDistrict.renAi': 'Ren’ai',
  'twDistrict.mingjian': 'Mingjian',
  'twDistrict.jiji': 'Jiji',
  'twDistrict.shuili': 'Shuili',
  'twDistrict.yuchi': 'Yuchi',
  'twDistrict.xinyi': 'Xinyi',
  'twDistrict.zhushan': 'Zhushan',
  'twDistrict.lugu': 'Lugu',
  'twDistrict.eastDistrict': 'East District',
  'twDistrict.westDistrict': 'West District',
  'twDistrict.fanlu': 'Fanlu',
  'twDistrict.meishan': 'Meishan',
  'twDistrict.zhuqi': 'Zhuqi',
  'twDistrict.alishan': 'Alishan',
  'twDistrict.zhongpu': 'Zhongpu',
  'twDistrict.dapu': 'Dapu',
  'twDistrict.shuishang': 'Shuishang',
  'twDistrict.lucao': 'Lucao',
  'twDistrict.taibao': 'Taibao',
  'twDistrict.puzi': 'Puzi',
  'twDistrict.dongshi': 'Dongshi',
  'twDistrict.liujiao': 'Liujiao',
  'twDistrict.xingang': 'Xingang',
  'twDistrict.minxiong': 'Minxiong',
  'twDistrict.dalin': 'Dalin',
  'twDistrict.xikou': 'Xikou',
  'twDistrict.yizhu': 'Yizhu',
  'twDistrict.budai': 'Budai',
  'twDistrict.dounan': 'Dounan',
  'twDistrict.dapi': 'Dapi',
  'twDistrict.huwei': 'Huwei',
  'twDistrict.tuku': 'Tuku',
  'twDistrict.baozhong': 'Baozhong',
  'twDistrict.dongshi': 'Dongshi',
  'twDistrict.taixi': 'Taixi',
  'twDistrict.lunbei': 'Lunbei',
  'twDistrict.mailiao': 'Mailiao',
  'twDistrict.douliu': 'Douliu',
  'twDistrict.linnei': 'Linnei',
  'twDistrict.gukeng': 'Gukeng',
  'twDistrict.citong': 'Citong',
  'twDistrict.xiluo': 'Xiluo',
  'twDistrict.erlun': 'Erlun',
  'twDistrict.beigang': 'Beigang',
  'twDistrict.shuilin': 'Shuilin',
  'twDistrict.kouhu': 'Kouhu',
  'twDistrict.sihu': 'Sihu',
  'twDistrict.yuanchang': 'Yuanchang',
  'twDistrict.westCentralDistrict': 'West Central District',
  'twDistrict.eastDistrict': 'East District',
  'twDistrict.southDistrict': 'South District',
  'twDistrict.northDistrict': 'North District',
  'twDistrict.anpingDistrict': 'Anping District',
  'twDistrict.annanDistrict': 'Annan District',
  'twDistrict.yongkangDistrict': 'Yongkang District',
  'twDistrict.guirenDistrict': 'Guiren District',
  'twDistrict.xinhuaDistrict': 'Xinhua District',
  'twDistrict.zuozhenDistrict': 'Zuozhen District',
  'twDistrict.yujingDistrict': 'Yujing District',
  'twDistrict.nanxiDistrict': 'Nanxi District',
  'twDistrict.nanhuaDistrict': 'Nanhua District',
  'twDistrict.rendeDistrict': 'Rende District',
  'twDistrict.guanmiaoDistrict': 'Guanmiao District',
  'twDistrict.longciDistrict': 'Longci District',
  'twDistrict.guantianDistrict': 'Guantian District',
  'twDistrict.madouDistrict': 'Madou District',
  'twDistrict.jialiDistrict': 'Jiali District',
  'twDistrict.xigangDistrict': 'Xigang District',
  'twDistrict.qiguDistrict': 'Qigu District',
  'twDistrict.jiangjunDistrict': 'Jiangjun District',
  'twDistrict.xuejiaDistrict': 'Xuejia District',
  'twDistrict.beimenDistrict': 'Beimen District',
  'twDistrict.xinyingDistrict': 'Xinying District',
  'twDistrict.houbiDistrict': 'Houbi District',
  'twDistrict.baiheDistrict': 'Baihe District',
  'twDistrict.dongshanDistrict': 'Dongshan District',
  'twDistrict.liujiaDistrict': 'Liujia District',
  'twDistrict.xiayingDistrict': 'Xiaying District',
  'twDistrict.liuyingDistrict': 'Liuying District',
  'twDistrict.yanshuiDistrict': 'Yanshui District',
  'twDistrict.shanhuaDistrict': 'Shanhua District',
  'twDistrict.daneiDistrict': 'Danei District',
  'twDistrict.shanshangDistrict': 'Shanshang District',
  'twDistrict.xinshiDistrict': 'Xinshi District',
  'twDistrict.andingDistrict': 'Anding District',
  'twDistrict.xinxingDistrict': 'Xinxing District',
  'twDistrict.qianjinDistrict': 'Qianjin District',
  'twDistrict.lingyaDistrict': 'Lingya District',
  'twDistrict.yanchengDistrict': 'Yancheng District',
  'twDistrict.gushanDistrict': 'Gushan District',
  'twDistrict.qijinDistrict': 'Qijin District',
  'twDistrict.qianzhenDistrict': 'Qianzhen District',
  'twDistrict.sanminDistrict': 'Sanmin District',
  'twDistrict.nanzihDistrict': 'Nanzih District',
  'twDistrict.xiaogangDistrict': 'Xiaogang District',
  'twDistrict.zuoyingDistrict': 'Zuoying District',
  'twDistrict.renwuDistrict': 'Renwu District',
  'twDistrict.dasheDistrict': 'Dashe District',
  'twDistrict.gangshanDistrict': 'Gangshan District',
  'twDistrict.luzhuDistrict': 'Luzhu District',
  'twDistrict.alianDistrict': 'Alian District',
  'twDistrict.tianliaoDistrict': 'Tianliao District',
  'twDistrict.yanchaoDistrict': 'Yanchao District',
  'twDistrict.qiaotouDistrict': 'Qiaotou District',
  'twDistrict.ziguanDistrict': 'Ziguan District',
  'twDistrict.mituoDistrict': 'Mituo District',
  'twDistrict.yongAnDistrict': "Yong'an District",
  'twDistrict.huneiDistrict': 'Hunei District',
  'twDistrict.fengshanDistrict': 'Fengshan District',
  'twDistrict.daliaoDistrict': 'Daliao District',
  'twDistrict.linyuanDistrict': 'Linyuan District',
  'twDistrict.niaosongDistrict': 'Niaosong District',
  'twDistrict.dashuDistrict': 'Dashu District',
  'twDistrict.qishanDistrict': 'Qishan District',
  'twDistrict.meinongDistrict': 'Meinong District',
  'twDistrict.liuguiDistrict': 'Liugui District',
  'twDistrict.neimenDistrict': 'Neimen District',
  'twDistrict.shanlinDistrict': 'Shanlin District',
  'twDistrict.jiaxianDistrict': 'Jiaxian District',
  'twDistrict.taoyuanDistrict': 'Taoyuan District',
  'twDistrict.namaxiaDistrict': 'Namaxia District',
  'twDistrict.maolinDistrict': 'Maolin District',
  'twDistrict.jiadingDistrict': 'Jiading District',
  'twDistrict.pratasIsland': 'Pratas Island',
  'twDistrict.spratlyIslands': 'Spratly Islands',
  'twDistrict.magong': 'Magong',
  'twDistrict.xiyu': 'Xiyu',
  'twDistrict.wangAn': 'Wang-an',
  'twDistrict.qimei': 'Qimei',
  'twDistrict.baisha': 'Baisha',
  'twDistrict.huxi': 'Huxi',
  'twDistrict.pingtung': 'Pingtung',
  'twDistrict.sandimen': 'Sandimen',
  'twDistrict.wutai': 'Wutai',
  'twDistrict.majia': 'Majia',
  'twDistrict.jiuru': 'Jiuru',
  'twDistrict.ligang': 'Ligang',
  'twDistrict.gaoshu': 'Gaoshu',
  'twDistrict.yanpu': 'Yanpu',
  'twDistrict.changzhi': 'Changzhi',
  'twDistrict.linluo': 'Linluo',
  'twDistrict.zhutian': 'Zhutian',
  'twDistrict.neipu': 'Neipu',
  'twDistrict.wandan': 'Wandan',
  'twDistrict.chaozhou': 'Chaozhou',
  'twDistrict.taiwu': 'Taiwu',
  'twDistrict.laiyi': 'Laiyi',
  'twDistrict.wanluan': 'Wanluan',
  'twDistrict.kanding': 'Kanding',
  'twDistrict.sinpi': 'Sinpi',
  'twDistrict.nanzhou': 'Nanzhou',
  'twDistrict.linbian': 'Linbian',
  'twDistrict.donggang': 'Donggang',
  'twDistrict.liuqiu': 'Liuqiu',
  'twDistrict.jiadong': 'Jiadong',
  'twDistrict.xinyuan': 'Xinyuan',
  'twDistrict.fangliao': 'Fangliao',
  'twDistrict.fangshan': 'Fangshan',
  'twDistrict.chunri': 'Chunri',
  'twDistrict.shizi': 'Shizi',
  'twDistrict.checheng': 'Checheng',
  'twDistrict.mudan': 'Mudan',
  'twDistrict.hengchun': 'Hengchun',
  'twDistrict.manzhou': 'Manzhou',
  'twDistrict.taitung': 'Taitung',
  'twDistrict.greenIsland': 'Green Island',
  'twDistrict.lanyu': 'Lanyu',
  'twDistrict.yanping': 'Yanping',
  'twDistrict.beinan': 'Beinan',
  'twDistrict.luye': 'Luye',
  'twDistrict.guanshan': 'Guanshan',
  'twDistrict.haiduan': 'Haiduan',
  'twDistrict.chishang': 'Chishang',
  'twDistrict.donghe': 'Donghe',
  'twDistrict.chenggong': 'Chenggong',
  'twDistrict.changbin': 'Changbin',
  'twDistrict.taimali': 'Taimali',
  'twDistrict.jinfeng': 'Jinfeng',
  'twDistrict.dawu': 'Dawu',
  'twDistrict.daren': 'Daren',
  'twDistrict.hualien': 'Hualien',
  'twDistrict.xincheng': 'Xincheng',
  'twDistrict.xiulin': 'Xiulin',
  'twDistrict.jiAn': "Ji'an",
  'twDistrict.shoufeng': 'Shoufeng',
  'twDistrict.fenglin': 'Fenglin',
  'twDistrict.guangfu': 'Guangfu',
  'twDistrict.fengbin': 'Fengbin',
  'twDistrict.ruisui': 'Ruisui',
  'twDistrict.wanrong': 'Wanrong',
  'twDistrict.yuli': 'Yuli',
  'twDistrict.zhuoxi': 'Zhuoxi',
  'twDistrict.fuli': 'Fuli',
  'twDistrict.jinsha': 'Jinsha',
  'twDistrict.jinhu': 'Jinhu',
  'twDistrict.jinning': 'Jinning',
  'twDistrict.jincheng': 'Jincheng',
  'twDistrict.lieyu': 'Lieyu',
  'twDistrict.wuqiu': 'Wuqiu',
  'twDistrict.nangan': 'Nangan',
  'twDistrict.beigan': 'Beigan',
  'twDistrict.juguang': 'Juguang',
  'twDistrict.dongyin': 'Dongyin',
  auto_pay_at: 'Auto Pay Date: ',
  soldOut: 'Sold out'
};
export default enUS;
